import {
    DISPLAY_AGENCY_PHONE_RESET,
    DISPLAY_AGENCY_PHONE_FETCH,
    DISPLAY_AGENCY_PHONE_FETCHED,
    DISPLAY_AGENCY_PHONE_ERROR
} from "../actions/types"

const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    phone: null,
    error: null
}

const DisplayAgencyPhoneReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DISPLAY_AGENCY_PHONE_FETCH:
            return {
                ...state,
                fetching: true,
                fetched: false
            }
        case DISPLAY_AGENCY_PHONE_FETCHED:
            return {
                ...state,
                fetching: false,
                fetched: true,
                phone: action.payload
            }
        case DISPLAY_AGENCY_PHONE_ERROR:
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: action.payload
            }
        case DISPLAY_AGENCY_PHONE_RESET:
            return INITIAL_STATE
        default:
            return state
    }
}

export default DisplayAgencyPhoneReducer