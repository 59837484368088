import {
    PROSPECTION_FETCH,
    PROSPECTION_FETCHED,
    PROSPECTION_ERROR,
    PROSPECTION_RESET,
    SIMILAR_PROSPECTIONS_FETCH,
    SIMILAR_PROSPECTIONS_FETCHED,
    SIMILAR_PROSPECTIONS_ERROR,
    SET_PROSPECTION_CURRENT_PARAMETERS,
    PROSPECTION_MARK_CHANGED,
    PROSPECTION_MARK_VALIDATE,
    PROSPECTION_MARK_VALIDATED,
    PROSPECTION_MARK_ERROR,
    PROSPECTION_MARK_FETCHED
} from "../actions/types"

const INITIAL_STATE = {
    current_parameters: null,
    origin_parameters: null,
    fetching: false,
    fetched: false,
    prospection: null,
    error: null,
    mark: null,
    form_mark: null,
    mark_validated: false,
    mark_validating: false,
    mark_error: null,
    ssr: false
}

const ProspectionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROSPECTION_FETCH:
            return {
                ...state,
                prospection: null,
                fetching: true,
                fetched: false,
                error: null
            }
        case PROSPECTION_FETCHED:
            return {
                ...state,
                prospection: action.payload,
                fetching: false,
                fetched: true,
                ssr: action.ssr
            }
        case PROSPECTION_ERROR:
            return {
                ...state,
                prospection: null,
                fetching: false,
                fetched: false,
                error: action.payload,
                ssr: action.ssr
            }
        case SIMILAR_PROSPECTIONS_FETCH:
            return {
                ...state,
                similar_prospections_fetching: true,
                similar_prospections_fetched: false,
                similar_prospections_error: null
            }
        case SIMILAR_PROSPECTIONS_FETCHED:
            return {
                ...state,
                similar_prospections: action.payload,
                similar_prospections_fetching: false,
                similar_prospections_fetched: true,
                ssr: action.ssr
            }
        case SIMILAR_PROSPECTIONS_ERROR:
            return {
                ...state,
                similar_prospections: null,
                similar_prospections_fetching: false,
                similar_prospections_fetched: false,
                error: action.payload,
                ssr: action.ssr
            }
        case SET_PROSPECTION_CURRENT_PARAMETERS:
            return {
                ...state,
                origin_parameters: action.payload.originParameters,
                current_parameters: action.payload.currentParameters
            }
        case PROSPECTION_RESET:
            return {
                ...state,
                ssr: INITIAL_STATE.ssr
            }
        case PROSPECTION_MARK_CHANGED:
            return {
                ...state,
                form_mark: action.payload
            }
        case PROSPECTION_MARK_VALIDATE:
            return {
                ...state,
                mark_validating: true,
                mark_validated: false,
                mark_error: INITIAL_STATE.mark_error
            }
        case PROSPECTION_MARK_VALIDATED:
            return {
                ...state,
                mark_validating: false,
                mark_validated: true,
                mark_error: INITIAL_STATE.mark_error
            }
        case PROSPECTION_MARK_ERROR:
            return {
                ...state,
                mark_validating: false,
                mark_validated: false,
                mark_error: action.payload
            }
        case PROSPECTION_MARK_FETCHED:
            return {
                ...state,
                mark: action.payload
            }
        default:
            return state
    }
}

export default ProspectionReducer