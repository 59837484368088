export default [
  {
    "dept_code": "01",
    "name": "l'Ain"
  },
  {
    "dept_code": "02",
    "name": "l'Aisne"
  },
  {
    "dept_code": "03",
    "name": "l'Allier"
  },
  {
    "dept_code": "04",
    "name": "les Alpes-de-Haute-Provence"
  },
  {
    "dept_code": "05",
    "name": "les Hautes-Alpes"
  },
  {
    "dept_code": "06",
    "name": "les Alpes-Maritimes"
  },
  {
    "dept_code": "07",
    "name": "l'Ardèche"
  },
  {
    "dept_code": "08",
    "name": "les Ardennes"
  },
  {
    "dept_code": "09",
    "name": "l'Ariège"
  },
  {
    "dept_code": "10",
    "name": "l'Aube"
  },
  {
    "dept_code": "11",
    "name": "l'Aude"
  },
  {
    "dept_code": "12",
    "name": "l'Aveyron"
  },
  {
    "dept_code": "13",
    "name": "les Bouches-du-Rhône"
  },
  {
    "dept_code": "14",
    "name": "le Calvados"
  },
  {
    "dept_code": "15",
    "name": "le Cantal"
  },
  {
    "dept_code": "16",
    "name": "la Charente"
  },
  {
    "dept_code": "17",
    "name": "la Charente-Maritime"
  },
  {
    "dept_code": "18",
    "name": "le Cher"
  },
  {
    "dept_code": "19",
    "name": "la Corrèze"
  },
  {
    "dept_code": "2A",
    "name": "la Corse-du-Sud"
  },
  {
    "dept_code": "2B",
    "name": "la Haute-Corse"
  },
  {
    "dept_code": "21",
    "name": "la Côte-d'or"
  },
  {
    "dept_code": "22",
    "name": "les Côtes-d'armor"
  },
  {
    "dept_code": "23",
    "name": "la Creuse"
  },
  {
    "dept_code": "24",
    "name": "la Dordogne"
  },
  {
    "dept_code": "25",
    "name": "le Doubs"
  },
  {
    "dept_code": "26",
    "name": "la Drôme"
  },
  {
    "dept_code": "27",
    "name": "l'Eure"
  },
  {
    "dept_code": "28",
    "name": "l'Eure-et-Loir"
  },
  {
    "dept_code": "29",
    "name": "le Finistère"
  },
  {
    "dept_code": "30",
    "name": "le Gard"
  },
  {
    "dept_code": "31",
    "name": "la Haute-Garonne"
  },
  {
    "dept_code": "32",
    "name": "le Gers"
  },
  {
    "dept_code": "33",
    "name": "la Gironde"
  },
  {
    "dept_code": "34",
    "name": "l'Hérault"
  },
  {
    "dept_code": "35",
    "name": "l'Ille-et-Vilaine"
  },
  {
    "dept_code": "36",
    "name": "l'Indre"
  },
  {
    "dept_code": "37",
    "name": "l'Indre-et-Loire"
  },
  {
    "dept_code": "38",
    "name": "l'Isère"
  },
  {
    "dept_code": "39",
    "name": "le Jura"
  },
  {
    "dept_code": "40",
    "name": "les Landes"
  },
  {
    "dept_code": "41",
    "name": "le Loir-et-Cher"
  },
  {
    "dept_code": "42",
    "name": "la Loire"
  },
  {
    "dept_code": "43",
    "name": "la Haute-loire"
  },
  {
    "dept_code": "44",
    "name": "la Loire-Atlantique"
  },
  {
    "dept_code": "45",
    "name": "le Loiret"
  },
  {
    "dept_code": "46",
    "name": "le Lot"
  },
  {
    "dept_code": "47",
    "name": "le Lot-et-Garonne"
  },
  {
    "dept_code": "48",
    "name": "la Lozère"
  },
  {
    "dept_code": "49",
    "name": "le Maine-et-Loire"
  },
  {
    "dept_code": "50",
    "name": "la Manche"
  },
  {
    "dept_code": "51",
    "name": "la Marne"
  },
  {
    "dept_code": "52",
    "name": "la Haute-Marne"
  },
  {
    "dept_code": "53",
    "name": "la Mayenne"
  },
  {
    "dept_code": "54",
    "name": "la Meurthe-et-Moselle"
  },
  {
    "dept_code": "55",
    "name": "la Meuse"
  },
  {
    "dept_code": "56",
    "name": "le Morbihan"
  },
  {
    "dept_code": "57",
    "name": "la Moselle"
  },
  {
    "dept_code": "58",
    "name": "la Nièvre"
  },
  {
    "dept_code": "59",
    "name": "le Nord"
  },
  {
    "dept_code": "60",
    "name": "l'Oise"
  },
  {
    "dept_code": "61",
    "name": "l'Orne"
  },
  {
    "dept_code": "62",
    "name": "le Pas-de-Calais"
  },
  {
    "dept_code": "63",
    "name": "le Puy-de-Dôme"
  },
  {
    "dept_code": "64",
    "name": "les Pyrénées-Atlantiques"
  },
  {
    "dept_code": "65",
    "name": "les Hautes-Pyrénées"
  },
  {
    "dept_code": "66",
    "name": "les Pyrénées-Orientales"
  },
  {
    "dept_code": "67",
    "name": "le Bas-Rhin"
  },
  {
    "dept_code": "68",
    "name": "le Haut-Rhin"
  },
  {
    "dept_code": "69",
    "name": "le Rhône"
  },
  {
    "dept_code": "70",
    "name": "la Haute-Saône"
  },
  {
    "dept_code": "71",
    "name": "la Saône-et-Loire"
  },
  {
    "dept_code": "72",
    "name": "la Sarthe"
  },
  {
    "dept_code": "73",
    "name": "la Savoie"
  },
  {
    "dept_code": "74",
    "name": "la Haute-Savoie"
  },
  {
    "dept_code": "75",
    "name": "Paris"
  },
  {
    "dept_code": "76",
    "name": "la Seine-Maritime"
  },
  {
    "dept_code": "77",
    "name": "la Seine-et-Marne"
  },
  {
    "dept_code": "78",
    "name": "les Yvelines"
  },
  {
    "dept_code": "79",
    "name": "les Deux-sèvres"
  },
  {
    "dept_code": "80",
    "name": "la Somme"
  },
  {
    "dept_code": "81",
    "name": "le Tarn"
  },
  {
    "dept_code": "82",
    "name": "le Tarn-et-Garonne"
  },
  {
    "dept_code": "83",
    "name": "le Var"
  },
  {
    "dept_code": "84",
    "name": "le Vaucluse"
  },
  {
    "dept_code": "85",
    "name": "la Vendée"
  },
  {
    "dept_code": "86",
    "name": "la Vienne"
  },
  {
    "dept_code": "87",
    "name": "la Haute-vienne"
  },
  {
    "dept_code": "88",
    "name": "les Vosges"
  },
  {
    "dept_code": "89",
    "name": "l'Yonne"
  },
  {
    "dept_code": "90",
    "name": "le Territoire de Belfort"
  },
  {
    "dept_code": "91",
    "name": "l'Essonne"
  },
  {
    "dept_code": "92",
    "name": "les Hauts-de-Seine"
  },
  {
    "dept_code": "93",
    "name": "la Seine-Saint-Denis"
  },
  {
    "dept_code": "94",
    "name": "le Val-de-Marne"
  },
  {
    "dept_code": "95",
    "name": "le Val-d'Oise"
  }
]