import React, { Component } from "react"
import Router, { withRouter } from "next/router"
import CookieConsent from "react-cookie-consent"
import toUpper from "lodash/toUpper"
import { isIE, browserVersion } from "react-device-detect"
import { CDN_DOMAIN } from "../config/cdn"
import { constructURL } from "../helpers/URL"
import { enableObfuscatedLink, enableDynamicPublicPagesLink } from "../helpers/SEO"

Router.onRouteChangeComplete = () => {
    if (window.gtag) {
        window.gtag("config", window.gaTrackingId, {
            page_location: window.location.href,
            page_path: window.location.pathname,
            page_title: window.document.title
        })
    }
}

class Layout extends Component {
    componentDidMount() {
        window.addEventListener('scroll', this.obfuscateLinks)
        window.addEventListener('mousemove', this.obfuscateLinks)
        window.addEventListener('scroll', this.enablePublicPagesDynamicLinks)
        window.addEventListener('mousemove', this.enablePublicPagesDynamicLinks)

        this.props.router.events.on('routeChangeComplete', this.handleRouteChange)

        const URL = constructURL({
            ...this.props.router.query,
            type: this.props.router.query.type
        })
        window.currentHref = URL.href
    }

    componentWillUnmount() {
        this.props.router.events.off('routeChangeComplete', this.handleRouteChange)

        const URL = constructURL({
            ...this.props.router.query,
            type: this.props.router.query.type
        })
        window.currentHref = URL.href
    }

    handleRouteChange = (url, informations) => {
        /*console.log(
            `App is changing to ${url}: `, this.props.router
        )*/
        const URL = constructURL({
            ...this.props.router.query,
            type: this.props.router.query.type
        })
        window.currentHref = URL.href
        delete window.publicPagesLink
    }

    obfuscateLinks = () => {
        if (window.linkObfuscation) {
            return
        }
        enableObfuscatedLink(this.props.children)
        window.linkObfuscation = true
    }

    enablePublicPagesDynamicLinks = () => {
        if (window.publicPagesLink) {
            return
        }
        enableDynamicPublicPagesLink(this.props.children)
        window.publicPagesLink = true
    }

    render() {
        return (
            <div>
                {this.props.children}
                {this.props.router.pathname !== "/liste-annonces-v2" && (
                    <CookieConsent
                        location="bottom"
                        buttonText={toUpper("J’ai compris")}
                        cookieName="cookie_consent"
                        style={{
                            background: `url(${CDN_DOMAIN}/static/bg_green_popup.png)`,
                            padding: 10,
                            zIndex: 20000,
                            justifyContent: "center",
                            alignItems: "flex-start"
                        }}
                        contentStyle={{
                            maxWidth:
                                isIE && browserVersion === "11.0"
                                    ? "100%"
                                    : "75%",
                            marginBottom: 0,
                            fontFamily:
                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                            fontSize: 15,
                            lineHeight: 1.5
                        }}
                        contentClasses="consent-cookie-content-block"
                        buttonStyle={{
                            fontFamily:
                                "Lato, Roboto, Helvetica, Arial, sans-serif",
                            color: "white",
                            fontWeight: 700,
                            border: "1px solid white",
                            borderRadius: 5,
                            background: "transparent",
                            fontSize: 15,
                            padding: 10
                        }}
                    >
                        En poursuivant votre navigation sur ce site, vous
                        acceptez l’utilisation des cookies qui nous permettent
                        d’assurer le bon fonctionnement de nos services, de vous
                        fournir un contenu personnalisé et de mesurer l’audience
                        de notre site. Pour en savoir plus, consultez notre politique
                        de protection des données.
                    </CookieConsent>
                )}
            </div>
        )
    }
}

export default withRouter(Layout)
