import { createGlobalStyle } from "styled-components"

const crisp = createGlobalStyle`
    div#crisp-chatbox > div > a {
        right: ${props => props.router && ['/toutes-les-annonces-v2', '/liste-annonces-v2'].indexOf(props.router.pathname) === -1 ? "24px" : "auto"} !important;
        left: ${props => props.router && ['/toutes-les-annonces-v2', '/liste-annonces-v2'].indexOf(props.router.pathname) === -1 ? "auto" : "24px"} !important;
    }

    div#crisp-chatbox > div > div[data-chat-status="initial"] {
        right: ${props => props.router && ['/toutes-les-annonces-v2', '/liste-annonces-v2'].indexOf(props.router.pathname) === -1 ? (props.isMobileOnly ? "auto" : "24px") : "auto"} !important;
        left: ${props => props.router && ['/toutes-les-annonces-v2', '/liste-annonces-v2'].indexOf(props.router.pathname) === -1 ? "auto" : (props.isMobileOnly ? "auto" : "24px")} !important;
    }

    div#crisp-chatbox > div > a > span > span[data-id="general_entice"] {
        right: ${props => props.router && ['/toutes-les-annonces-v2', '/liste-annonces-v2'].indexOf(props.router.pathname) === -1 ? "23px" : "auto"} !important;
        left: ${props => props.router && ['/toutes-les-annonces-v2', '/liste-annonces-v2'].indexOf(props.router.pathname) === -1 ? "auto" : "23px"} !important;
    }
`

export { crisp }