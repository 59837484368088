import {
    ADS_DATAS_PUBLIC_PAGE_FETCHED
} from "../actions/types"

const INITIAL_STATE = {
    ads_datas_fetching: false,
    ads_datas_fetched: false,
    ads_datas: null
}

const PublicPageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADS_DATAS_PUBLIC_PAGE_FETCHED:
            return {
                ...state,
                ads_datas_fetching: false,
                ads_datas_fetched: true,
                ads_datas: action.payload
            }
        default:
            return state
    }
}

export default PublicPageReducer