/* eslint-disable no-underscore-dangle */

import {
    createMuiTheme
} from '@material-ui/core/styles'

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#0cb2ae',
            main: '#0cb2ae',
            dark: '#0cb2ae'
        },
        secondary: {
            light: '#0cb2ae',
            main: '#0cb2ae',
            dark: '#0cb2ae'
        }
    },
    typography: {
        fontFamily: 'Lato, sans-serif',
        // fontWeightLight: 300,
        // fontWeightRegular: 300,
        // fontWeightMedium: 300,
        // fontWeightBold: 300,
        // fontSize: 13,
        // lineHeight: 2
    },
    overrides: {
        MuiTypography: {
            body2: {
                fontWeight: 300,
                lineHeight: "1.71429em",
            },
        },
        MuiCssBaseline: {
            "@global": {
                body: {
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: "1rem",
                    fontWeight: 400,
                    lineHeight: 1.5,
                },
            },
        },
        /* MuiButton: {
            text: {
                padding: "0px 15px",
            },
        }, */
        MuiCardHeader: {
            root: {
                "@media (min-width: 600px)": {
                    paddingLeft: 24,
                    paddingRight: 24,
                },
            },
        },
        MuiCardContent: {
            root: {
                "@media (min-width: 600px)": {
                    paddingLeft: 24,
                    paddingRight: 24,
                },
            },
        },
        MuiFormControlLabel: {
            label: {
                fontSize: 14,
            }
        }
    },
})

export default theme
