import { CDN_DOMAIN } from './config/cdn'

export default {
    title:
        "Recherche location et vente de local commercial et terrain",
    titleTemplate: "%s - UnEmplacement.com",
    description:
        "Envoyez gratuitement votre recherche de local commercial, fonds de commerce ou terrain à + de 2000 agents immobiliers et foncières spécialisés en immobilier commercial et accédez aux meilleurs emplacements en centre-ville, centre commercial, gare, retail-park, périphérie et zone industrielle.",
    // canonical: "https://unemplacement.com/",
    // dangerouslySetAllPagesToNoIndex: true,
    // dangerouslySetAllPagesToNoFollow: true,
    openGraph: {
        type: "website",
        locale: "fr_FR",
        url: "https://unemplacement.com/",
        title:
            "Recherche location et vente de local commercial et terrain - UnEmplacement.com",
        description:
            "Envoyez gratuitement votre recherche de local commercial, fonds de commerce ou terrain à + de 2000 agents immobiliers et foncières spécialisés en immobilier commercial et accédez aux meilleurs emplacements en centre-ville, centre commercial, gare, retail-park, périphérie et zone industrielle.",
        defaultImageWidth: 300,
        defaultImageHeight: 300,
        // Multiple Open Graph images is only available in version `7.0.0-canary.0`+ of next
        images: [
            {
                url: `${CDN_DOMAIN}/static/uec-logo-300x300.png`,
                width: 300,
                height: 300,
                alt: "Logo UnEmplacement.com"
            }
        ],
        site_name: "UnEmplacement.com"
    } /*,
    twitter: {
      handle: '@handlea',
      site: '@sitea',
      cardType: 'summary_large_image',
    },
    facebook: {
      appId: 1234567890,
    },*/
}
