import { createGlobalStyle } from "styled-components"

export const guide = createGlobalStyle`
    a.category-title:visited,
    a.category-title:link,
    a.category-title:active {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        font-size: 22px;
        font-weight: 700;
        color: rgb(28, 175, 171);
        text-decoration: none;
        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
    }

    a.category-title:hover {
        color: rgb(13, 193, 163);
    }

    span.subcategory-link,
    a.subcategory-link:visited,
    a.subcategory-link:link,
    a.subcategory-link:active {
        font-family: Open Sans, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #39415f;
        text-decoration: none;
        line-height: 1.3;
        width: 100%;
        height: 38px;
        display: block;
        padding: 3px;
        padding-left: 15px;
        border-radius: 5px;
        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        display: flex;
        flexDirection: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    span.subcategory-link > span,
    a.subcategory-link > span {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    span.subcategory-link:hover,
    a.subcategory-link:hover {
        background: rgba(57, 65, 95, 0.05);
    }

    div.subcategories-block {
        margin-left: 25px;
        margin-right: 20px;
        margin-bottom: 10px;
        margin-top: 5px;
        overflow: hidden;
        min-height: ${3 * 38}px;
    }

    div.subcategories-expand-block {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 5px;
        margin-left: 25px;
        margin-right: 20px;
        margin-bottom: 10px;
        color: #39415f;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
    }

    div.subcategories-expand-block:hover {
        background: rgba(57, 65, 95, 0.03);
    }

    div.subcategories-expand-block > span {
        margin-left: 10px;
        margin-right: 10px;
    }

    .front-listing > .gradient-black {
        min-height: 400px;
        background-image: linear-gradient(90deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.85) 18%, rgba(0,0,0,0.4) 33%, rgba(0,0,0,0) 100%);
        height: 100%;
        width: 100%;
    }

    .front-listing > .gradient-black-article {
        min-height: 400px;
        background: rgba(0,0,0,0.6);
        height: 100%;
        width: 100%;
    }

    div.guide-description {
        font-family: Open Sans, Helvetica, Arial, sans-serif;
        text-shadow: none;
        font-size: 14px;
        padding-bottom: 20px;
        color: white;
    }

    ul.uec-guide-breadcrumb {
        margin-left: 0;
        padding-left: 0;
        margin: 0;
        margin-bottom: 16px;
        display: table;
        list-style-type: none;
    }
    
    ul.uec-guide-breadcrumb li {
        font-family: Open Sans, Helvetica, Arial, sans-serif;
        color: white;
        font-size: 12px;
        display: table-cell;
        vertical-align: middle;
    }

    @media (max-width: 550px) {
        ul.uec-guide-breadcrumb li {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 80px;
        }
    }
    
    .uec-guide-breadcrumb a,
    .uec-guide-breadcrumb a:visited,
    .uec-guide-breadcrumb a:link,
    .uec-guide-breadcrumb a:active {
        font-family: Open Sans, Helvetica, Arial, sans-serif;
        color: white;
        font-size: 12px;
        text-decoration: none;
    }
    
    .uec-guide-breadcrumb a:hover {
        text-decoration: underline;
    }

    .uec-guide-breadcrumb-mobile.breadcrumb-compact li.pos-5,
    .uec-guide-breadcrumb-mobile.breadcrumb-compact li.pos-4-next,
    .uec-guide-breadcrumb-mobile.breadcrumb-compact li.pos-0,
    .uec-guide-breadcrumb-mobile.breadcrumb-compact li.pos-0-next {
        display: none !important;
    }

    .uec-guide-breadcrumb-mobile li,
    .uec-guide-breadcrumb-mobile a,
    .uec-guide-breadcrumb-mobile a:visited,
    .uec-guide-breadcrumb-mobile a:link,
    .uec-guide-breadcrumb-mobile a:active {
        font-size: 10px !important;
    }

    .article-title a {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        font-size: 20px;
        font-weight: 700;
        color: rgb(57, 65, 95);
        text-decoration: none;
        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
    }

    .article-title a:hover {
        color: rgb(13, 193, 163);
    }

    a.article-button {
        font-family: EB Garamond, Open Sans, Helvetica, Arial, sans-serif;
        color: white;
        text-decoration: none;
    }

    @media (max-width: 680px) {
        .menu-guide-search {
            display: none !important;
        }
        .menu-guide-search-2 {
            display: flex !important;
            margin-bottom: 30px;
        }
    }
    @media (min-width: 681px) {
        .menu-guide-search {
            display: flex !important;
        }
        .menu-guide-search-2 {
            display: none !important;
        }
    }

    .article-block h2 {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        font-size: 24px;
        line-height: 1.9;
        color: #0db3ac;
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 700;
    }

    @media (max-width: 680px) {
        .article-block h2 {
            line-height: 1.3;
            font-size: 19px;
            margin-top: 10px;
            margin-bottom: 10px;
            font-weight: 700;
        }    
    }

    .article-block h3 {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        font-size: 20px;
        line-height: 1.5;
        color: #0db3ac;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    @media (max-width: 680px) {
        .article-block h3 {
            line-height: 1;
            font-size: 15px;
            margin-top: 10px;
            margin-bottom: 10px;
        }    
    }

    span.article-text {
        font-family: EB Garamond, Open Sans, Helvetica, Arial, sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 1.9;
        color: rgb(90, 90, 90);
    }

    span.article-text p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
    }

    article table td {
        font-family: EB Garamond, Open Sans, Helvetica, Arial, sans-serif;
        font-size: 18px;
        color: rgb(90, 90, 90);
    }

    div.article-summary div {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        color: #787878;
        font-weight: 700;
    }

    div.article-summary > ul.first {
        list-style-type: none;
        font-family: Open Sans, Helvetica, Arial, sans-serif;
        color: #0db3ac;
        font-size: 13px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 0px;
        padding-left: 0px;
    }

    div.article-summary > ul.first > li {
        padding-bottom: 10px;
        padding-right: 10px;
    }

    div.article-summary > ul.first > li > a {
        margin-left: 0px;
        font-family: Open Sans, Helvetica, Arial, sans-serif;
        color: #787878;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        text-decoration: none;
    }

    div.article-summary ul.second {
        list-style-type: none;
        padding-left: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    div.article-summary ul.second li {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    div.article-summary ul.second li a {
        font-family: Open Sans, Helvetica, Arial, sans-serif;
        color: #787878;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        text-decoration: none;
    }

    @media (max-width: 800px) {
        div.article-summary ul.first {
            font-size: 16px;
            margin-left: 0px;
            padding-left: 15px;
            line-height: 1;
        }

        div.article-summary ul.first li {
            margin-top: 5px;
        }

        div.article-summary ul.first li a {
            margin-left: 0px;
            font-size: 13px;
        }
    }

    div.article-summary ul.first li a:hover {
        color: #0db3ac;
    }

    div.article-summary li.highlight-menu > a {
        color: #0db3ac !important;
        font-weight: 600 !important;
    }

    @media (max-width: 680px) {
        .article-block ul {
            padding-left: 15px;
        }
    }

    ul.uec-guide-breadcrumb a {
        cursor: pointer;
        position: relative;
        text-decoration: none;
    }

    ul.uec-guide-breadcrumb a.bc-link:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 6px solid white;
        border-top: 6px solid transparent;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        bottom: -10px;
        left: calc(50% - 6px);
        -webkit-animation-name: fadeInUp2;
        animation-name: fadeInUp2;
        animation-duration: 500ms;
        animation-delay: 0ms;
        animation-iteration-count: 1;
    }

    .bc-nav-block {
        z-index: 10000;
        position: absolute;
        top: -6px;
        left: 0px;
        right: 0px;
    }
    
    .bc-nav-block-paper {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-duration: 500ms;
        animation-delay: 0ms;
        animation-iteration-count: 1;
    }

    @-webkit-keyframes fadeInUp2 {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInUp2 {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @-webkit-keyframes fadeInUp {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @-webkit-keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
    }

    @keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
    }

    .article-fab-button {
        transition: all .2s ease-in-out !important;
        -moz-transition: all .2s ease-in-out !important;
        -webkit-transition: all .2s ease-in-out !important;
    }

    .share-article > div {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        color: #787878;
        font-size: 18px;
        font-weight: 600;
    }

    @media (max-width: 680px) {
        .share-article {
            text-align: center;
        }

        .guide-mark {
            text-align: center;
        }

        .guide-mark-star {
            justify-content: center;
        }
    }

    .network-share {
        display: flex;
        text-align: center;
        margin-top: 10px;
    }

    @media (max-width: 680px) {
        .network-share {
            justify-content: space-evenly;
        }
    }

    .network-share-button {
        cursor: pointer;
        margin-right: 10px;
    }

    .network-share-button:hover:not(:active) {
        opacity: 0.75;
    }

    .article-cross-title h3 {
        line-height: 1.2 !important;
        margin: 0;
    }

    .article-cross-title a {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        font-size: 19px;
        font-weight: 700;
        color: rgb(57, 65, 95);
        text-decoration: none;
        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
    }

    .article-cross-title a:hover {
        color: rgb(13, 193, 163);
    }

    .cross-articles-block {
        margin-top: 20px;
    }

    .cross-articles-block > h2 {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        color: rgb(57,65,95);
        text-align: center;
        font-size: 27px;
        font-weight: 700;
        margin: auto;
        margin-bottom: 20px;
    }

    @media (min-width: 1200px) {
        .container-restrict {
            width: 1000px !important;
            max-width: 100%;
        }
    }

    @media (max-width: 990px) {
        .container-restrict-crosslinks {
            width: 100% !important;
            max-width: 100%;
        }
    }

    @media (max-width: 990px) {
        .uec-guide-header-button-block {
            max-width: 160px !important;
        }
        .uec-guide-header-button-block a {
            width: 160px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
            border-radius: 8px !important;
        }
    }

    @media (max-width: 680px) {
        .uec-guide-header-button-block {
            max-width: 140px !important;
        }
        .uec-guide-header-button-block a {
            width: 140px !important;
            font-size: 12px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
            border-radius: 8px !important;
        }
    }

    section.comments {
        margin-top: 40px;
    }

    a.guide-menu-link:visited,
    a.guide-menu-link:link,
    a.guide-menu-link:active {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        color: rgb(120, 120, 120);
        text-decoration: none !important;
        line-height: 1.3;
    }
    
    a.guide-menu-link:hover {
        color: #0db3ac;
        text-decoration: none;
    }

    a.guide-menu-link-green:visited,
    a.guide-menu-link-green:link,
    a.guide-menu-link-green:active {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        color: #0db3ac;
        text-decoration: none !important;
        line-height: 1.3;
    }
    
    a.guide-menu-link-green:hover {
        color: #0db3ac;
        text-decoration: none;
    }

    .guide-description a:visited,
    .guide-description a:link,
    .guide-description a:active {
        color: white;
    }

    .guide-description a:hover {
        color: white;
    }

    div.guide-menu-title {
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
        margin-top: 5px;
        color: #0db3ac;
    }

    .ellipsis-block {
        line-height: 1.5;
        text-align: justify;
    }

    li.intro {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-family: Titillium Web, Helvetica, Arial, sans-serif;
        color: rgb(120, 120, 120);
        text-decoration: none !important;
        line-height: 1.3;   
    }

    @media (max-width: 900px) {
        .guide-menu-block {
            display: none;
        }
    }

    .guide-top-bar-scrolled {
        background: white !important;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    }

    .guide-top-bar {
        left: 0;
        top: 0;
        right: 0;
        bottom: auto;
        position: relative;
        z-index: 200;
        transition: background-color .2s ease-in, height .3s ease-in;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 62.5px;
        padding-left: 20px;
        padding-right: 20px;
        background: #0db3ac;
    }

    .article-text img {
        display: block;
        margin: auto;
    }

    .article-text ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }

    .article-text a {
        color: #0db3ac;
        transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        text-decoration: underline;
    }

    .article-text a:hover {
        color: #0db3ac;
        text-decoration: none;
    }

    @media (max-width: 900px) {
        .front-listing-guide {
            -webkit-clip-path: ellipse(160% 43% at 42% 10%) !important;
            clip-path: ellipse(160% 43% at 42% 10%) !important;
            min-height: 700px !important;
        }

        .front-listing-guide-2 {
            -webkit-clip-path: none !important;
            clip-path: none !important;
            background: none !important;
        }

        .article-title {
            font-size: 20px !important;
            margin: 0 !important;
            padding: 0 !important;
        }

        .article-block {
            margin-top: 40px !important;
        }

        .article-custom table td {
            font-size: 10px !important;
        }
    }

    .article-custom {
        text-align: center;
    }

    .article-custom iframe {
        margin: auto;
    }

    .menu-white ul {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap: wrap;
        list-style-type: none;
        margin-bottom: 0px;
        margin-top: 20px;
    }
    
    .menu-white li {
        margin-right: 20px;
        display: flex;
        align-items: flex-end;
    }

    .menu-white a:hover,
    .menu-white a:visited,
    .menu-white a:link,
    .menu-white a:active,
    a.menu-white-item {
        font-family: Lato, Roboto, Helvetica, Arial, sans-serif !important;
        color: white !important;
        font-weight: 600 !important;
        font-size: 15px !important;
        text-decoration: none !important;
        line-height: 1.5;
        margin-bottom: 15px;
        padding-bottom: 3px;
        border-bottom: 2px solid transparent !important;
    }

    .menu-white a:hover,
    a.menu-white-item:hover,
    .menu-white a.hover,
    a.menu-white-item.hover {
        border-bottom: 2px solid white !important;
    }

    .guide-top-bar-scrolled .menu-white a:hover,
    .guide-top-bar-scrolled .menu-white a:visited,
    .guide-top-bar-scrolled .menu-white a:link,
    .guide-top-bar-scrolled .menu-white a:active,
    .guide-top-bar-scrolled a.menu-white-item {
        color: black !important;
        border-bottom: 2px solid transparent !important;
    }

    .guide-top-bar-scrolled .menu-white a:hover,
    .guide-top-bar-scrolled a.menu-white-item:hover,
    .guide-top-bar-scrolled .menu-white a.hover,
    .guide-top-bar-scrolled a.menu-white-item.hover {
        border-bottom: 2px solid #0db3ac !important;
    }

    @media (max-width: 1300px) {
        .menu-white {
            display: none;
        }

        .hideConnectionButtonGuide {
            display: none !important;
        }
    }

    ul.dropdown-guide {
        margin-top: 0;
        box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15),
          0 2px 4px 0 rgba(93, 100, 148, 0.2);
        background: white;
        border-radius: 5px;
        list-style: none;
        display: none;
        margin-left: 0px;
        padding-left: 0px;
        position: absolute;
        min-width: 205px;
        padding: 10px;
        padding-right: 0px;
        padding-top: 17px;
        padding-bottom: 0;
        transition: transform 0.25s ease-out, opacity 0.25s ease-out;
    }

    ul.dropdown-guide li {
        margin-right: 0 !important;
    }

    a.dropdown-guide-link:link,
    a.dropdown-guide-link:visited,
    a.dropdown-guide-link:active {
        font-weight: 400 !important;
        font-family: Lato, Roboto, Helvetica, Arial, sans-serif !important;
        color: black !important;
        border-bottom: 0px !important;
    }

    a.dropdown-guide-link:hover {
        font-weight: 400 !important;
        font-family: Lato, Roboto, Helvetica, Arial, sans-serif !important;
        color: #0db3ac !important;
        text-decoration: normal !important;
        border-bottom: 0px !important;
    }

    .dropdown-guide-button:hover ul.dropdown-guide {
        display: flex;
        flex-direction: column;
    }

    @media (min-width: 1300px) {
        .header-burger-button-guide,
        .header-burger-button-scrolled-guide {
            display: none;
        }
    }

    .header-burger-button-scrolled-guide .bm-burger-bars {
        background: #ffffff !important;
    }

    .header-burger-button-scrolled-guide .bm-burger-bars-hover {
        background: #ffffff !important;
    }

    .warning-block a:link,
    .warning-block a:visited,
    .warning-block a:active {
        color: #d32f2f !important;
        text-decoration: underline;
    }

    .warning-block a:hover {
        color: #d32f2f !important;
    }

    .note-block, .warning-block {
        font-weight: 400;
    }

    .note-block a:link,
    .note-block a:visited,
    .note-block a:active {
        color: #0db3ac !important;
        text-decoration: underline;
    }

    .note-block a:hover {
        color: #0db3ac !important;
    }

    .article-custom p  {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
    }
`