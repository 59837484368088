import _ from "lodash"
import URLSearchParams from "@ungap/url-search-params"

import typesURLMatching from "../datas/typesURLMatching"
import departmentsWordLink from "../datas/departmentsWordLink"
import reverseSpecialCase from "../datas/reverseSpecialCase"
import zerofill from "../helpers/zerofill"

const constructURLAgency = (query, params = {}, propertyCompany = false) => {
    const { agencyName, agencyURLId, state, department, city } = query

    let queryParams = new URLSearchParams(_.pickBy(params, o => o))
    let completeQueryParams = new URLSearchParams(
        _.pickBy(
            { agencyName, agencyURLId, state, department, city, ...params },
            o => o
        )
    )
    const stringCQP = completeQueryParams.toString()
    const stringQP = queryParams.toString()

    return {
        href: `/agence-immobiliere-vitrine${stringCQP !== "" ? "?" + stringCQP : ""}`,
        as: `/${propertyCompany ? 'fonciere' : 'agence-immobiliere'}/${agencyName}-${agencyURLId}${
            stringQP !== "" ? "?" + stringQP : ""
        }`
    }
}

const constructURL = (query, params = {}) => {
    const { type, state, department, city, transaction, activity } = query

    let queryParams = new URLSearchParams(_.pickBy(params, o => o))
    let completeQueryParams = new URLSearchParams(
        _.pickBy(
            { type, state, department, city, transaction, activity, ...params },
            o => o
        )
    )
    const stringCQP = completeQueryParams.toString()
    const stringQP = queryParams.toString()

    return {
        href: `/liste-annonces-v2${stringCQP !== "" ? "?" + stringCQP : ""}`,
        as: `/${typesURLMatching[type]}${
            (state && !city) || department === "paris-75" ? "/" + state : ""
        }${department && department !== "paris-75" ? "/" + department : ""}${
            city ? "/" + city : ""
        }${transaction ? "/" + transaction : ""}${
            stringQP !== "" ? "?" + stringQP : ""
        }`
    }
}

const constructURLPageBase = (query, params = {}) => {
    const { type, transaction, activity } = query

    let queryParams = new URLSearchParams(_.pickBy(params, o => o))
    let completeQueryParams = new URLSearchParams(
        _.pickBy(
            { type, transaction, activity, ...params },
            o => o
        )
    )
    const stringCQP = completeQueryParams.toString()
    const stringQP = queryParams.toString()

    return {
        href: `/toutes-les-annonces-v2${stringCQP !== "" ? "?" + stringCQP : ""}`,
        as: `/${typesURLMatching[type]}${
            stringQP !== "" ? "?" + stringQP : ""
        }`
    }
}

const getStringParams = params => {
    const queryParams = new URLSearchParams(_.pickBy(params, o => o))
    const stringQueryParams = queryParams.toString()

    return stringQueryParams !== '' ? `?${stringQueryParams}` : ''
}

const getLocationLibelle = (location, postalCodeInseeMatching = {}, worldLink = false) => {
    let locationLibelle = location.libelle

    if (['commune', 'commune_all'].indexOf(location.type) !== -1) {
        const searchSpecialCase = reverseSpecialCase.filter(o => location.codePostal && o.insee === location.code && o.cp === location.codePostal)
        let insee = location.code
        if (searchSpecialCase.length > 0) {
            insee = searchSpecialCase[0].real_insee
        }

        locationLibelle = locationLibelle
            .replace('Paris (Tout arrondissement)', 'Paris (75000)')
            .replace('Lyon (Tout arrondissement)', 'Lyon (69000)')
            .replace('Marseille (Tout arrondissement)', 'Marseille (13000)')
        if (
            postalCodeInseeMatching &&
            typeof postalCodeInseeMatching[zerofill(insee, 5)] !== 'undefined'
        ) {
            locationLibelle = locationLibelle.replace(/\(.+\)/i, `(${postalCodeInseeMatching[zerofill(insee, 5)]})`)
        }
    }

    if (['departement'].indexOf(location.type) !== -1) {
        if (worldLink) {
            locationLibelle = `${departmentsWordLink.filter(o => o.dept_code === location.codeDepartement)[0].name} (${location.codeDepartement})`
        }
        if (!worldLink) {
            locationLibelle = `${location.nom} (${location.codeDepartement})`
        }
    }

    return locationLibelle
}

export {
    constructURL,
    constructURLPageBase,
    constructURLAgency,
    getStringParams,
    getLocationLibelle
}
