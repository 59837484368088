import _ from "lodash"

import {
    TRANSACTIONS_FETCH,
    TRANSACTIONS_FETCHED,
    TRANSACTIONS_RESET,
    TRANSACTIONS_COUNT_FETCH,
    TRANSACTIONS_COUNT_FETCHED,
    TRANSACTIONS_COUNT_ERROR,
    TRANSACTIONS_COUNT_RESET
} from "../actions/types"

const INITIAL_STATE = {
    list: [],
    fetching: false,
    fetched: false,
    page: null,
    previous: null,
    hasPrevious: false,
    next: null,
    hasNext: false,
    totalTransactions: null,
    count: null,
    count_fetching: false,
    count_fetched: false,
    ssr: false
}

const TransactionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRANSACTIONS_FETCH:
            return {
                ...state,
                fetching: true,
                fetched: false
            }
        case TRANSACTIONS_FETCHED:
            return {
                ...state,
                fetching: false,
                fetched: true,
                list: action.payload.transactions,
                previous: action.payload.previous,
                hasPrevious: action.payload.hasPrevious,
                next: action.payload.next,
                hasNext: action.payload.hasNext,
                page:
                    action.payload.transactions.length === 0 ?
                        null : !action.payload.hasPrevious ?
                            1 : action.next ?
                                state.page + 1 : action.previous ?
                                    state.page - 1 : null,
                totalTransactions:
                    typeof action.payload.totalTransactions !== 'undefined' ?
                        action.payload.totalTransactions : state.totalTransactions,
                ssr: action.ssr
            }
        case TRANSACTIONS_COUNT_FETCH:
            return {
                ...state,
                count_fetching: true,
                count_fetched: false,
                count: null
            }
        case TRANSACTIONS_COUNT_FETCHED:
            return {
                ...state,
                count_fetching: false,
                count_fetched: true,
                count_error: null,
                count: action.payload
            }
        case TRANSACTIONS_COUNT_ERROR:
            return {
                ...state,
                count_fetching: false,
                count_fetched: false,
                count_error: action.payload
            }
        case TRANSACTIONS_RESET:
            return {
                ...state,
                ssr: INITIAL_STATE.ssr
            }
        case TRANSACTIONS_COUNT_RESET:
            return {
                ...state,
                count: INITIAL_STATE.count,
                count_fetching: INITIAL_STATE.count_fetching,
                count_fetched: INITIAL_STATE.count_fetched
            }
        default:
            return state
    }
}

export default TransactionReducer