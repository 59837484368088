import {
    SHOW_VIDEO_POPUP,
    HIDE_VIDEO_POPUP,
    LAST_PROSPECTIONS_FETCH,
    LAST_PROSPECTIONS_FETCHED,
    LAST_PROSPECTIONS_ERROR,
    SHOW_PRO_SUBSCRIBE_DIALOG,
    HIDE_PRO_SUBSCRIBE_DIALOG,
    SHOW_STATE_SUBSCRIBE_DIALOG,
    HIDE_STATE_SUBSCRIBE_DIALOG,
    SHOW_DEMO_DIALOG,
    HIDE_DEMO_DIALOG
} from "../actions/types"

const INITIAL_STATE = {
    pro_subcribe_dialog_opened: false,
    state_subscribe_dialog_opened: false,
    demo_dialog_opened: false,
    videoPopupOpened: false,
    last_prospections: null,
    last_prospections_fetching: false,
    last_prospections_fetched: false,
    last_prospections_error: null,
    ssr: false
}

const HomepageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_VIDEO_POPUP:
            return {
                ...state,
                videoPopupOpened: true
            }
        case HIDE_VIDEO_POPUP:
            return {
                ...state,
                videoPopupOpened: false
            }
        case LAST_PROSPECTIONS_FETCH:
            return {
                ...state,
                last_prospections: null,
                last_prospections_fetching: true,
                last_prospections_fetched: false,
                last_prospections_error: null
            }
        case LAST_PROSPECTIONS_FETCHED:
            return {
                ...state,
                last_prospections: action.payload,
                last_prospections_fetching: false,
                last_prospections_fetched: true,
                last_prospections_error: null,
                ssr: action.ssr
            }
        case LAST_PROSPECTIONS_ERROR:
            return {
                ...state,
                last_prospections_fetching: false,
                last_prospections_fetched: false,
                last_prospections_error: action.payload,
                ssr: action.ssr
            }
        case SHOW_PRO_SUBSCRIBE_DIALOG:
            return {
                ...state,
                pro_subscribe_dialog_opened: true
            }
        case HIDE_PRO_SUBSCRIBE_DIALOG:
            return {
                ...state,
                pro_subscribe_dialog_opened: false
            }
        case SHOW_STATE_SUBSCRIBE_DIALOG:
            return {
                ...state,
                state_subscribe_dialog_opened: true
            }
        case HIDE_STATE_SUBSCRIBE_DIALOG:
            return {
                ...state,
                state_subscribe_dialog_opened: false
            }
        case SHOW_DEMO_DIALOG:
            return {
                ...state,
                demo_dialog_opened: true
            }
        case HIDE_DEMO_DIALOG:
            return {
                ...state,
                demo_dialog_opened: false
            }
        default:
            return state
    }
}

export default HomepageReducer