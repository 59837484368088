import _ from "lodash"

import MD5 from "../helpers/MD5"

import {
    CHANGE_STATUS,
    CHANGE_TYPE,
    CHANGE_MIN,
    CHANGE_MAX,
    ADD_LOCATION,
    REMOVE_LOCATION,
    SET_FILTERED_LOCATIONS,
    COMPUTE_TARGET,
    COMPUTED_TARGET,
    CHANGE_EMAIL,
    CHANGE_EMAIL_CONFIRMATION,
    SUBMIT_FIRST_STEP,
    SUBMITTED_FIRST_STEP,
    RESET_FIRST_STEP,
    ERROR_SUBMITTED_FIRST_STEP,
    SUBMIT_SECOND_STEP,
    SUBMITTED_SECOND_STEP,
    RESET_SECOND_STEP,
    ERROR_SUBMITTED_SECOND_STEP,
    SUBMIT_THIRD_STEP,
    SUBMITTED_THIRD_STEP,
    RESET_THIRD_STEP,
    ERROR_SUBMITTED_THIRD_STEP,
    SUBMIT_FOURTH_STEP,
    RESET_FOURTH_STEP,
    SUBMITTED_FOURTH_STEP,
    ERROR_SUBMITTED_FOURTH_STEP,
    SUBMIT_FIFTH_STEP,
    RESET_FIFTH_STEP,
    SUBMITTED_FIFTH_STEP,
    ERROR_SUBMITTED_FIFTH_STEP,
    GO_TO_STEP,
    SAVE_OLD_PHONE,
    /* first step */
    CHANGE_FIRSTNAME,
    CHANGE_LASTNAME,
    CHANGE_POSITION,
    CHANGE_BRAND,
    CHANGE_ACTIVITY_TYPE,
    CHANGE_PHONE,
    /* first step */
    /* prospection */
    NAME_CHANGED,
    IS_FIELD_CHANGED,
    REST_SURFACE_ACCESS_CHANGED,
    GROUNDFLOOR_SURFACE_CHANGED,
    REST_SURFACE_CHANGED,
    SURFACE_ATTRIBUTES_CHANGED,
    FACADE_LENGTH_CHANGED,
    HEIGHT_LENGTH_CHANGED,
    COS_CHANGED,
    MIN_BUILDING_HEIGHT_CHANGED,
    LEASE_CHANGED,
    LEASE_ATTRIBUTES_CHANGED,
    PRICE_CHANGED,
    PRICE_ATTRIBUTES_CHANGED,
    CENTER_CHANGED,
    NUMBER_ONE_CHANGED,
    NUMBER_ONE_BIS_CHANGED,
    AXE_PASSANT_CHANGED,
    MALL_CHANGED,
    GALERIE_CHANGED,
    TRANSPORTATION_CHANGED,
    SUBURB_CHANGED,
    COMMERCIAL_AREA_CHANGED,
    INDUSTRIAL_AREA_CHANGED,
    BUY_CHANGED,
    REQUIRED_LICENSE_4_CHANGED,
    ALLOWED_CATERING_INDUSTRY_CHANGED,
    DIAMETER_CHANGED,
    ALLOWED_CATERING_INDUSTRY_WITH_EXTRACTION_CHANGED,
    PMR_CHANGED,
    PARKING_CHANGED,
    CLOSE_PARKING_CHANGED,
    COMMENT_CHANGED,
    LOCATIONS_COMMENT_CHANGED,
    ACTIVITY_TYPE_CHANGED,
    DISPLAY_NUMBER_CHANGED,
    FUNDING_CHANGED,
    CONCEPT_CHANGED,
    COMMERCIAL_INDUSTRIAL_DESTINATION_CHANGED,
    HABITATION_DESTINATION_CHANGED,
    BUY_ONLY_CHANGED,
    ALLOW_NON_BUILDING_LAND_CHANGED,
    /* prospection */
    QUIT_TERMINATED_FORM,
    SUBSCRIBE_BRAND_TOGGLE_PRIVACY_POLICY
} from "../actions/types"

const INITIAL_STATE = {
    status: null,
    type: null,
    min: "",
    max: "",
    locations: [],
    filteredLocations: [],
    target: null,
    targetFetching: false,
    targetFetched: false,
    targetCache: {},
    totalCache: {},
    email: "",
    emailConfirmation: "",
    submittingFirstStep: false,
    submittedFirstStep: false,
    errorSubmittedFirstStep: false,
    errorSubmittedFirstStepMessage: null,
    submittingSecondStep: false,
    submittedSecondStep: false,
    errorSubmittedSecondStep: false,
    submittingThirdStep: false,
    submittedThirdStep: false,
    errorSubmittedThirdStep: false,
    submittingFourthStep: false,
    submittedFourthStep: false,
    errorSubmittedFourthStep: false,
    submittingFifthStep: false,
    submittedFifthStep: false,
    errorSubmittedFifthStep: false,
    subscriptionId: null,
    currentStep: 1,
    stepDirection: "forward",
    firstname: "",
    lastname: "",
    position: "",
    brand: "",
    activity_type: "",
    phone: "",
    oldPhone: null,
    privacyPolicy: false,
    prospection: {
        name: "",
        is_field: false,
        rest_surface_access: false,
        groundfloor_surface: { min: "", max: 1000 },
        rest_surface: { min: "", max: "" },
        surface_attributes: "only_groundfloor",
        facade_length: 10,
        height_length: 2.8,
        cos: 1,
        min_building_height: "",
        lease: "",
        lease_attributes: "m2",
        price: "",
        price_attributes: "max",
        center: false,
        number_one: false,
        number_one_bis: false,
        axe_passant: false,
        mall: false,
        galerie: false,
        transportation: false,
        suburb: false,
        commercial_area: false,
        industrial_area: false,
        underground_access: false,
        second_floor_access: false,
        buy: false,
        required_license_4: false,
        allowed_catering_industry: false,
        diameter: 1,
        allowed_catering_industry_with_extraction: false,
        pmr: false,
        parking: false,
        close_parking: false,
        comment: "",
        locations_comment: "",
        locations: [],
        activity_type: false,
        display_number: null,
        funding: null,
        concept: "",
        commercial_industrial_destination: false,
        habitation_destination: false,
        buy_only: false,
        allow_non_building_land: false
    }
}

const SubscribeBrandReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_STATUS:
            return {
                ...state,
                status: action.payload,
                prospection: {
                    ...state.prospection,
                    lease_attributes:
                        action.payload === "1" || action.payload === "3"
                            ? "month"
                            : "m2"
                }
            }
        case CHANGE_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case CHANGE_MIN:
            return {
                ...state,
                min: action.payload
            }
        case CHANGE_MAX:
            return {
                ...state,
                max: action.payload
            }
        case ADD_LOCATION:
            return {
                ...state,
                locations: !_.find(state.locations, {
                    libelle: action.payload.libelle
                })
                    ? [...state.locations, action.payload]
                    : state.locations
            }
        case REMOVE_LOCATION:
            return {
                ...state,
                locations: state.locations.filter(l => l.id !== action.payload)
            }
        case SET_FILTERED_LOCATIONS:
            return {
                ...state,
                filteredLocations: [...action.payload]
            }
        case COMPUTE_TARGET:
            return {
                ...state,
                target: null,
                targetFetching: true,
                targetFetched: false
            }
        case COMPUTED_TARGET:
            return {
                ...state,
                target: action.payload,
                targetCache: {
                    ...state.targetCache,
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    [MD5(
                        _.reduce(
                            action.locations,
                            (result, value, key) => result + value.id,
                            ""
                        )
                    )]: action.payload
                },
                totalCache: {
                    ...state.totalCache,
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    [MD5(
                        _.reduce(
                            action.locations,
                            (result, value, key) => result + value.id,
                            ""
                        )
                    )]:
                        _.reduce(
                            action.payload,
                            (result, value, key) => {
                                return result + value
                            },
                            0
                        ) < 80
                            ? _.random(80, 90)
                            : _.reduce(
                                  action.payload,
                                  (result, value, key) => {
                                      return result + value
                                  },
                                  0
                              )
                },
                targetFetching: false,
                targetFetched: true
            }
        case CHANGE_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case CHANGE_EMAIL_CONFIRMATION:
            return {
                ...state,
                emailConfirmation: action.payload
            }
        case SUBMIT_FIRST_STEP:
            return {
                ...state,
                submittingFirstStep: true,
                submittedFirstStep: false,
                errorSubmittedFirstStep: false
            }
        case SUBMITTED_FIRST_STEP:
            return {
                ...state,
                submittingFirstStep: false,
                submittedFirstStep: true,
                errorSubmittedFirstStep: false,
                subscriptionId: action.payload.subscriptionId,
                prospection: {
                    ...state.prospection,
                    rest_surface: { min: state.min, max: state.max },
                    is_field: state.type !== "1",
                    locations: [...state.locations]
                }
            }
        case RESET_FIRST_STEP:
            return {
                ...state,
                submittingFirstStep: false,
                submittedFirstStep: false,
                errorSubmittedFirstStep: false,
                errorSubmittedFirstStepMessage: null
            }
        case ERROR_SUBMITTED_FIRST_STEP:
            return {
                ...state,
                submittingFirstStep: false,
                submittedFirstStep: false,
                errorSubmittedFirstStep: true,
                errorSubmittedFirstStepMessage: action.payload
            }
        case SUBMIT_SECOND_STEP:
            return {
                ...state,
                submittingSecondStep: true,
                submittedSecondStep: false,
                errorSubmittedSecondStep: false
            }
        case SUBMITTED_SECOND_STEP:
            return {
                ...state,
                submittingSecondStep: false,
                submittedSecondStep: true,
                errorSubmittedSecondStep: false
            }
        case ERROR_SUBMITTED_SECOND_STEP:
            return {
                ...state,
                submittingSecondStep: false,
                submittedSecondStep: false,
                errorSubmittedSecondStep: true,
                errorSubmittedSecondStepMessage: action.payload
            }
        case RESET_SECOND_STEP:
            return {
                ...state,
                submittingSecondStep: false,
                submittedSecondStep: false,
                errorSubmittedSecondStep: false
            }
        case SUBMIT_THIRD_STEP:
            return {
                ...state,
                submittingThirdStep: true,
                submittedThirdStep: false,
                errorSubmittedThirdStep: false
            }
        case SUBMITTED_THIRD_STEP:
            return {
                ...state,
                submittingThirdStep: false,
                submittedThirdStep: true,
                errorSubmittedThirdStep: false
            }
        case RESET_THIRD_STEP:
            return {
                ...state,
                submittingThirdStep: false,
                submittedThirdStep: false,
                errorSubmittedThirdStep: false
            }
        case ERROR_SUBMITTED_THIRD_STEP:
            return {
                ...state,
                submittingThirdStep: false,
                submittedThirdStep: false,
                errorSubmittedThirdStep: true,
                errorSubmittedThirdStepMessage: action.payload
            }
        case SUBMIT_FOURTH_STEP:
            return {
                ...state,
                submittingFourthStep: true,
                submittedFourthStep: false,
                errorSubmittedFourthStep: false
            }
        case SUBMITTED_FOURTH_STEP:
            return {
                ...state,
                submittingFourthStep: false,
                submittedFourthStep: true,
                errorSubmittedFourthStep: false
            }
        case RESET_FOURTH_STEP:
            return {
                ...state,
                submittingFourthStep: false,
                submittedFourthStep: false,
                errorSubmittedFourthStep: false
            }
        case ERROR_SUBMITTED_FOURTH_STEP:
            return {
                ...state,
                submittingFourthStep: false,
                submittedFourthStep: false,
                errorSubmittedFourthStep: true,
                errorSubmittedFourthStepMessage: action.payload
            }
        case SUBMIT_FIFTH_STEP:
            return {
                ...state,
                submittingFifthStep: true,
                submittedFifthStep: false,
                errorSubmittedFifthStep: false
            }
        case RESET_FIFTH_STEP:
            return {
                ...state,
                submittingFifthStep: false,
                submittedFifthStep: false,
                errorSubmittedFifthStep: false
            }
        case SUBMITTED_FIFTH_STEP:
            return {
                ...state,
                submittingFifthStep: false,
                submittedFifthStep: true,
                status: INITIAL_STATE.status,
                min: INITIAL_STATE.min,
                max: INITIAL_STATE.max,
                locations: INITIAL_STATE.locations,
                filteredLocations: INITIAL_STATE.filteredLocations,
                targetFetching: INITIAL_STATE.targetFetching,
                targetFetched: INITIAL_STATE.targetFetched,
                email: INITIAL_STATE.email,
                emailConfirmation: INITIAL_STATE.emailConfirmation,
                stepDirection: INITIAL_STATE.stepDirection,
                lastname: INITIAL_STATE.lastname,
                position: INITIAL_STATE.position,
                brand: INITIAL_STATE.brand,
                activity_type: INITIAL_STATE.activity_type,
                phone: INITIAL_STATE.phone,
                oldPhone: INITIAL_STATE.oldPhone,
                prospection: INITIAL_STATE.prospection
            }
        case ERROR_SUBMITTED_FIFTH_STEP:
            return {
                ...state,
                submittingFifthStep: false,
                submittedFifthStep: false,
                errorSubmittedFifthStep: true,
                errorSubmittedFifthStepMessage: action.payload
            }
        case GO_TO_STEP:
            return {
                ...state,
                currentStep: action.payload,
                stepDirection:
                    action.payload < state.stepDirection ? "back" : "forward"
            }
        case SAVE_OLD_PHONE:
            return {
                ...state,
                oldPhone: action.payload
            }
        case CHANGE_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload
            }
        case CHANGE_LASTNAME:
            return {
                ...state,
                lastname: action.payload
            }
        case CHANGE_POSITION:
            return {
                ...state,
                position: action.payload
            }
        case CHANGE_BRAND:
            return {
                ...state,
                brand: action.payload
            }
        case CHANGE_ACTIVITY_TYPE:
            return {
                ...state,
                activity_type: action.payload
            }
        case CHANGE_PHONE:
            return {
                ...state,
                phone: action.payload
            }
        case NAME_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, name: action.payload }
            }
        case IS_FIELD_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, is_field: action.payload }
            }
        case REST_SURFACE_ACCESS_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    rest_surface_access: action.payload
                }
            }
        case GROUNDFLOOR_SURFACE_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    groundfloor_surface: action.payload
                }
            }
        case REST_SURFACE_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    rest_surface: {
                        min: action.payload.min,
                        max: action.payload.max
                    }
                }
            }
        case SURFACE_ATTRIBUTES_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    surface_attributes: action.payload
                }
            }
        case FACADE_LENGTH_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    facade_length: action.payload
                }
            }
        case HEIGHT_LENGTH_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    height_length: action.payload
                }
            }
        case COS_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, cos: action.payload }
            }
        case MIN_BUILDING_HEIGHT_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    min_building_height: action.payload
                }
            }
        case LEASE_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, lease: action.payload }
            }
        case LEASE_ATTRIBUTES_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    lease_attributes: action.payload
                }
            }
        case PRICE_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, price: action.payload }
            }
        case PRICE_ATTRIBUTES_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    price_attributes: action.payload
                }
            }
        case CENTER_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    center: action.payload,
                    number_one: false,
                    number_one_bis: false,
                    axe_passant: false
                }
            }
        case NUMBER_ONE_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    number_one: action.payload
                }
            }
        case NUMBER_ONE_BIS_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    number_one_bis: action.payload
                }
            }
        case AXE_PASSANT_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    axe_passant: action.payload
                }
            }
        case MALL_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, mall: action.payload }
            }
        case GALERIE_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, galerie: action.payload }
            }
        case TRANSPORTATION_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    transportation: action.payload
                }
            }
        case SUBURB_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, suburb: action.payload }
            }
        case COMMERCIAL_AREA_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    commercial_area: action.payload
                }
            }
        case INDUSTRIAL_AREA_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    industrial_area: action.payload
                }
            }
        case BUY_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, buy: action.payload }
            }
        case REQUIRED_LICENSE_4_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    required_license_4: action.payload
                }
            }
        case ALLOWED_CATERING_INDUSTRY_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    allowed_catering_industry: action.payload
                }
            }
        case DIAMETER_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, diameter: action.payload }
            }
        case ALLOWED_CATERING_INDUSTRY_WITH_EXTRACTION_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    allowed_catering_industry_with_extraction: action.payload
                }
            }
        case PMR_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, pmr: action.payload }
            }
        case PARKING_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, parking: action.payload }
            }
        case CLOSE_PARKING_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    close_parking: action.payload
                }
            }
        case COMMENT_CHANGED:
            return {
                ...state,
                prospection: { ...state.prospection, comment: action.payload }
            }
        case LOCATIONS_COMMENT_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    locations_comment: action.payload
                }
            }
        case ACTIVITY_TYPE_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    activity_type: action.payload
                }
            }
        case DISPLAY_NUMBER_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    display_number: action.payload
                }
            }
        case FUNDING_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    funding: action.payload
                }
            }
        case CONCEPT_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    concept: action.payload
                }
            }
        case COMMERCIAL_INDUSTRIAL_DESTINATION_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    commercial_industrial_destination: action.payload
                }
            }
        case HABITATION_DESTINATION_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    habitation_destination: action.payload
                }
            }
        case BUY_ONLY_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    buy_only: action.payload
                }
            }
        case ALLOW_NON_BUILDING_LAND_CHANGED:
            return {
                ...state,
                prospection: {
                    ...state.prospection,
                    allow_non_building_land: action.payload
                }
            }
        case QUIT_TERMINATED_FORM:
            return {
                ...state,
                type: INITIAL_STATE.type,
                subscriptionId: INITIAL_STATE.subscriptionId,
                target: INITIAL_STATE.target,
                targetCache: INITIAL_STATE.targetCache,
                totalCache: INITIAL_STATE.totalCache,
                firstname: INITIAL_STATE.firstname,
                currentStep: INITIAL_STATE.currentStep
            }
        case SUBSCRIBE_BRAND_TOGGLE_PRIVACY_POLICY:
            return {
                ...state,
                privacyPolicy: action.payload
            }
        default:
            return state
    }
}

export default SubscribeBrandReducer