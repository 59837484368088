import _ from "lodash"

import {
    PROSPECTIONS_STATE_FETCH,
    PROSPECTIONS_STATE_FETCHED,
    PROSPECTIONS_STATE_RESET,
    PROSPECTIONS_STATE_MARK_CHANGED,
    SET_CURRENT_PARAMETERS,
    PROSPECTIONS_COUNT_FETCH,
    PROSPECTIONS_COUNT_FETCHED,
    PROSPECTIONS_COUNT_ERROR,
    PROSPECTIONS_STATE_MARK_VALIDATE,
    PROSPECTIONS_STATE_MARK_VALIDATED,
    PROSPECTIONS_STATE_MARK_ERROR,
    PROSPECTIONS_STATE_MARK_FETCHED,
    CITIES_PUBLIC_PAGE_FETCH,
    CITIES_PUBLIC_PAGE_FETCHED,
    CITIES_PUBLIC_PAGE_ERROR,
    CITIES_PUBLIC_PAGE_FETCH_V2,
    CITIES_PUBLIC_PAGE_FETCHED_V2,
    CITIES_PUBLIC_PAGE_ERROR_V2,
    DEPARTMENTS_PUBLIC_PAGE_FETCH_V2,
    DEPARTMENTS_PUBLIC_PAGE_FETCHED_V2,
    DEPARTMENTS_PUBLIC_PAGE_ERROR_V2,
    STATES_PUBLIC_PAGE_FETCH_V2,
    STATES_PUBLIC_PAGE_FETCHED_V2,
    STATES_PUBLIC_PAGE_ERROR_V2,
    COUNTRIES_PUBLIC_PAGE_FETCH_V2,
    COUNTRIES_PUBLIC_PAGE_FETCHED_V2,
    COUNTRIES_PUBLIC_PAGE_ERROR_V2,
    DEPARTMENTS_PUBLIC_PAGE_FETCH,
    DEPARTMENTS_PUBLIC_PAGE_FETCHED,
    DEPARTMENTS_PUBLIC_PAGE_ERROR,
    STATES_PUBLIC_PAGE_FETCH,
    STATES_PUBLIC_PAGE_FETCHED,
    STATES_PUBLIC_PAGE_ERROR,
    PROSPECTIONS_VIEW_INFOS_FETCH,
    PROSPECTIONS_VIEW_INFOS_FETCHED
} from "../actions/types"

const INITIAL_STATE = {
    state_list: [],
    state_fetching: false,
    state_fetched: false,
    reference: "",
    current_location: "",
    location_infos_requesting: false,
    location_infos_request_error: "",
    locations: [],
    type_filter: [],
    price: { min: 0, max: 31 },
    price_step: 25000,
    lease: { min: 0, max: 21000 },
    rdc: { min: 0, max: 1000 },
    total: { min: 0, max: 800 },
    state_page: null,
    state_total: null,
    state_real_total_fetching: false,
    state_real_total_fetched: false,
    state_real_total_error: null,
    state_real_total: null,
    current_parameters: null,
    origin_parameters: null,
    mark: null,
    form_mark: null,
    mark_validated: false,
    mark_validating: false,
    mark_error: null,
    public_page: null,
    public_page_fetching: false,
    public_page_fetched: false,
    public_page_error: null,
    public_page_v2: null,
    public_page_fetching_v2: false,
    public_page_fetched_v2: false,
    public_page_error_v2: null,
    countries_public_page_v2: null,
    countries_public_page_fetching_v2: false,
    countries_public_page_fetched_v2: false,
    countries_public_page_error_v2: null,
    view_infos: null,
    view_infos_fetching: false,
    view_infos_fetched: false,
    view_infos_error: null,
    ssr: false,
    redirecting_to: false
}

const ProspectionStateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROSPECTIONS_STATE_FETCH:
            return {
                ...state,
                state_fetching: true,
                state_fetched: false,
                redirecting_to: typeof action.redirecting_to !== 'undefined' ? action.redirecting_to : false
            }
        case PROSPECTIONS_STATE_FETCHED:
            return {
                ...state,
                state_fetching: false,
                state_fetched: true,
                state_list: action.payload[0],
                state_page: action.payload[1],
                state_total: action.payload[2],
                ssr: action.ssr,
                redirecting_to: INITIAL_STATE.redirecting_to
            }
        case PROSPECTIONS_COUNT_FETCH:
            return {
                ...state,
                state_real_total_fetching: true,
                state_real_total_fetched: false,
                state_real_total_error: null,
                state_real_total: null
            }
        case PROSPECTIONS_COUNT_FETCHED:
            return {
                ...state,
                state_real_total_fetching: false,
                state_real_total_fetched: true,
                state_real_total_error: null,
                state_real_total: action.payload[2],
                ssr: action.ssr
            }
        case PROSPECTIONS_COUNT_ERROR:
            return {
                ...state,
                state_real_total_fetching: false,
                state_real_total_fetched: false,
                state_real_total_error: action.payload
            }
        case SET_CURRENT_PARAMETERS:
            return {
                ...state,
                origin_parameters: action.payload.originParameters,
                current_parameters: action.payload.currentParameters
            }
        case PROSPECTIONS_STATE_RESET:
            return {
                ...state,
                ssr: INITIAL_STATE.ssr
            }
        case PROSPECTIONS_STATE_MARK_CHANGED:
            return {
                ...state,
                form_mark: action.payload
            }
        case PROSPECTIONS_STATE_MARK_VALIDATE:
            return {
                ...state,
                mark_validating: true,
                mark_validated: false,
                mark_error: INITIAL_STATE.mark_error
            }
        case PROSPECTIONS_STATE_MARK_VALIDATED:
            return {
                ...state,
                mark_validating: false,
                mark_validated: true,
                mark_error: INITIAL_STATE.mark_error
            }
        case PROSPECTIONS_STATE_MARK_ERROR:
            return {
                ...state,
                mark_validating: false,
                mark_validated: false,
                mark_error: action.payload
            }
        case PROSPECTIONS_STATE_MARK_FETCHED:
            return {
                ...state,
                mark: action.payload
            }
        case CITIES_PUBLIC_PAGE_FETCH:
            return {
                ...state,
                public_page_fetching: true,
                public_page_fetched: false,
                public_page_error: null
            }
        case CITIES_PUBLIC_PAGE_FETCHED:
            return {
                ...state,
                public_page_fetching: false,
                public_page_fetched: true,
                public_page: action.payload,
                public_page_error: null
            }
        case CITIES_PUBLIC_PAGE_ERROR:
            return {
                ...state,
                public_page_fetching: false,
                public_page_fetched: false,
                public_page: null,
                public_page_error: action.payload
            }
        case CITIES_PUBLIC_PAGE_FETCH_V2:
            return {
                ...state,
                public_page_fetching_v2: true,
                public_page_fetched_v2: false,
                public_page_error_v2: null
            }
        case CITIES_PUBLIC_PAGE_FETCHED_V2:
            return {
                ...state,
                public_page_fetching_v2: false,
                public_page_fetched_v2: true,
                public_page_v2: action.payload,
                public_page_error_v2: null
            }
        case CITIES_PUBLIC_PAGE_ERROR_V2:
            return {
                ...state,
                public_page_fetching_v2: false,
                public_page_fetched_v2: false,
                public_page_v2: null,
                public_page_error_v2: action.payload
            }
        case DEPARTMENTS_PUBLIC_PAGE_FETCH_V2:
            return {
                ...state,
                public_page_fetching_v2: true,
                public_page_fetched_v2: false,
                public_page_error_v2: null
            }
        case DEPARTMENTS_PUBLIC_PAGE_FETCHED_V2:
            return {
                ...state,
                public_page_fetching_v2: false,
                public_page_fetched_v2: true,
                public_page_v2: action.payload,
                public_page_error_v2: null
            }
        case DEPARTMENTS_PUBLIC_PAGE_ERROR_V2:
            return {
                ...state,
                public_page_fetching_v2: false,
                public_page_fetched_v2: false,
                public_page_v2: null,
                public_page_error_v2: action.payload
            }
        case STATES_PUBLIC_PAGE_FETCH_V2:
            return {
                ...state,
                public_page_fetching_v2: true,
                public_page_fetched_v2: false,
                public_page_error_v2: null
            }
        case STATES_PUBLIC_PAGE_FETCHED_V2:
            return {
                ...state,
                public_page_fetching_v2: false,
                public_page_fetched_v2: true,
                public_page_v2: action.payload,
                public_page_error_v2: null
            }
        case STATES_PUBLIC_PAGE_ERROR_V2:
            return {
                ...state,
                public_page_fetching_v2: false,
                public_page_fetched_v2: false,
                public_page_v2: null,
                public_page_error_v2: action.payload
            }
        case COUNTRIES_PUBLIC_PAGE_FETCH_V2:
            return {
                ...state,
                countries_public_page_fetching_v2: true,
                countries_public_page_fetched_v2: false,
                countries_public_page_error_v2: null
            }
        case COUNTRIES_PUBLIC_PAGE_FETCHED_V2:
            return {
                ...state,
                countries_public_page_fetching_v2: false,
                countries_public_page_fetched_v2: true,
                countries_public_page_v2: action.payload,
                countries_public_page_error_v2: null
            }
        case COUNTRIES_PUBLIC_PAGE_ERROR_V2:
            return {
                ...state,
                countries_public_page_fetching_v2: false,
                countries_public_page_fetched_v2: false,
                countries_public_page_v2: null,
                countries_public_page_error_v2: action.payload
            }
        case DEPARTMENTS_PUBLIC_PAGE_FETCH:
            return {
                ...state,
                public_page_fetching: true,
                public_page_fetched: false,
                public_page_error: null
            }
        case DEPARTMENTS_PUBLIC_PAGE_FETCHED:
            return {
                ...state,
                public_page_fetching: false,
                public_page_fetched: true,
                public_page: action.payload,
                public_page_error: null
            }
        case DEPARTMENTS_PUBLIC_PAGE_ERROR:
            return {
                ...state,
                public_page_fetching: false,
                public_page_fetched: false,
                public_page: null,
                public_page_error: action.payload
            }
        case STATES_PUBLIC_PAGE_FETCH:
            return {
                ...state,
                public_page_fetching: true,
                public_page_fetched: false,
                public_page_error: null
            }
        case STATES_PUBLIC_PAGE_FETCHED:
            return {
                ...state,
                public_page_fetching: false,
                public_page_fetched: true,
                public_page: action.payload,
                public_page_error: null
            }
        case STATES_PUBLIC_PAGE_ERROR:
            return {
                ...state,
                public_page_fetching: false,
                public_page_fetched: false,
                public_page: null,
                public_page_error: action.payload
            }
        case PROSPECTIONS_VIEW_INFOS_FETCH:
            return {
                ...state,
                view_infos_fetching: true,
                view_infos_fetched: false,
                view_infos_error: null
            }
        case PROSPECTIONS_VIEW_INFOS_FETCHED:
            return {
                ...state,
                view_infos_fetching: false,
                view_infos_fetched: true,
                view_infos: action.payload,
                view_infos_error: null
            }
        default:
            return state
    }
}

export default ProspectionStateReducer