import Router from "next/router"
import axios from "../config/axios"

const instance = axios.create({
    baseURL: '/api',
    timeout: 30000
})

export const generateObfuscatedLink = (url, anchor, classname = "olink", style = {}) => {
    const encodedURL =
        typeof btoa === "function"
            ? btoa(url) : Buffer.from(url).toString("base64")
    if (typeof window !== "undefined") {
        delete window.linkObfuscation
    }
    return (
        <span
            style={style}
            className={`${classname} olinkc`}
            data-path={encodedURL}
        >
            {anchor}
        </span>
    )
}

export const generateObfuscatedLinkString = (url, anchor, classname = "olink", style = "") => {
    const encodedURL =
        typeof btoa === "function"
            ? btoa(url) : Buffer.from(url).toString("base64")
    if (typeof window !== "undefined") {
        delete window.linkObfuscation
    }
    return `<span class="${classname} olinkc" data-path="${encodedURL}" style="${style}">${anchor}</span>`
}

export const generateObfuscatedDynamicLinkString = (url, anchor, classname = "olink", style = "") => {
    const encodedURL =
        typeof btoa === "function"
            ? btoa(url) : Buffer.from(url).toString("base64")
    if (typeof window !== "undefined") {
        delete window.linkObfuscation
    }
    return `<span class="${classname} odlinkc" data-path="${encodedURL}" style="${style}">${anchor}</span>`
}

export const generateObfuscatedLinkEncodedURL = url => {
    const encodedURL =
        typeof btoa === "function"
            ? btoa(url) : Buffer.from(url).toString("base64")
    if (typeof window !== "undefined") {
        delete window.linkObfuscation
    }
    return encodedURL
}

export const enableObfuscatedLink = () => {
    const list = document.querySelectorAll('span.olinkc[data-path]')
    
    for (let i = 0; i < list.length; i++) {
        if (typeof list[i].onclick !== "function") {
            const decodedURL =
                typeof atob === "function"
                    ? atob(list[i].dataset.path) : Buffer.from(list[i].dataset.path, "base64")
            list[i].addEventListener("click", e => {
                const link = document.createElement("a")
                link.href = decodedURL
                link.click()
                e.preventDefault()
            })
        }
    }

    const list2 = document.querySelectorAll('span.odlinkc[data-path]')
    
    for (let i = 0; i < list2.length; i++) {
        if (typeof list2[i].onclick !== "function") {
            const decodedURL =
                typeof atob === "function"
                    ? atob(list2[i].dataset.path) : Buffer.from(list2[i].dataset.path, "base64")
            list2[i].addEventListener("click", e => {
                e.preventDefault()
                setTimeout(() => {
                    instance
                        .get("/plistp", {
                            params: {
                                path: decodedURL
                            }
                        })
                        .then(res => {
                            const {
                                type,
                                state,
                                department,
                                city
                            } = res.data
                            // console.log('test: ', `/liste-annonces-v2?type=${type || ''}&state=${state || ''}&department=${department || ''}&city=${city || ''}&redirectTo=${encodeURIComponent(decodedURL)}`, decodedURL)
                            Router.push(`/liste-annonces-v2?type=${type || ''}&state=${state || ''}&department=${department || ''}&city=${city || ''}&redirectTo=${encodeURIComponent(decodedURL)}`, decodedURL)
                        }).catch(error => {
                            const link = document.createElement("a")
                            link.href = decodedURL
                            link.click()  
                        })
                }, 100)
            })
        }
    }
}

export const enableDynamicPublicPagesLink = function () {
    const list = document.querySelectorAll('a.opplink[data-path]')

    for (let i = 0; i < list.length; i++) {
        if (typeof list[i].onclick !== "function") {
            list[i].addEventListener("click", e => {
                e.preventDefault()
                if (e.target.getAttribute('data-path') === null) {
                    const link = document.createElement("a")
                    link.href = e.target.getAttribute('href')
                    link.click()
                }
                if (e.target.getAttribute('data-path') !== null) {
                    setTimeout(() => {
                        instance
                            .get("/plistp", {
                                params: {
                                    path: list[i].dataset.path
                                }
                            })
                            .then(res => {
                                const {
                                    type,
                                    state,
                                    department,
                                    city
                                } = res.data
                                // console.log('test: ', `/liste-annonces-v2?type=${type || ''}&state=${state || ''}&department=${department || ''}&city=${city || ''}&redirectTo=${encodeURIComponent(list[i].dataset.path)}`, list[i].dataset.path)
                                Router.push(`/liste-annonces-v2?type=${type || ''}&state=${state || ''}&department=${department || ''}&city=${city || ''}&redirectTo=${encodeURIComponent(list[i].dataset.path)}`, list[i].dataset.path)
                            }).catch(error => {
                                const link = document.createElement("a")
                                link.href = list[i].dataset.path
                                link.click()
                            })
                    }, 100)
                }
            })
        }
    }
}