import {
    INDUSTRIAL_AREAS_FETCH,
    INDUSTRIAL_AREAS_FETCHED,
    INDUSTRIAL_AREAS_ERROR
} from "../actions/types"

const INITIAL_STATE = {
    industrial_areas_fetching: false,
    industrial_areas_fetched: false,
    industrial_areas: [],
    industrial_areas_error: null
}

const IndustrialAreasReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INDUSTRIAL_AREAS_FETCH:
            return {
                ...state,
                industrial_areas_fetching: true,
                industrial_areas_fetched: false,
                industrial_areas_error: INITIAL_STATE.industrial_areas_error
            }
        case INDUSTRIAL_AREAS_FETCHED:
            return {
                ...state,
                industrial_areas_fetching: false,
                industrial_areas_fetched: true,
                industrial_areas: action.payload,
                industrial_areas_error: INITIAL_STATE.industrial_areas_error
            }
        case INDUSTRIAL_AREAS_ERROR:
            return {
                ...state,
                industrial_areas_fetching: false,
                industrial_areas_fetched: false,
                industrial_areas_error: action.payload
            }
        default:
            return state
    }
}

export default IndustrialAreasReducer