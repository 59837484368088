import {
    SHOPPING_MALLS_FETCH,
    SHOPPING_MALLS_FETCHED,
    SHOPPING_MALLS_ERROR
} from "../actions/types"

const INITIAL_STATE = {
    shopping_malls_fetching: false,
    shopping_malls_fetched: false,
    shopping_malls: [],
    shopping_malls_error: null
}

const ShoppingMallsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOPPING_MALLS_FETCH:
            return {
                ...state,
                shopping_malls_fetching: true,
                shopping_malls_fetched: false,
                shopping_malls_error: INITIAL_STATE.shopping_malls_error
            }
        case SHOPPING_MALLS_FETCHED:
            return {
                ...state,
                shopping_malls_fetching: false,
                shopping_malls_fetched: true,
                shopping_malls: action.payload,
                shopping_malls_error: INITIAL_STATE.shopping_malls_error
            }
        case SHOPPING_MALLS_ERROR:
            return {
                ...state,
                shopping_malls_fetching: false,
                shopping_malls_fetched: false,
                shopping_malls_error: action.payload
            }
        default:
            return state
    }
}

export default ShoppingMallsReducer