import {
    BODACC_PRICES_FETCH,
    BODACC_PRICES_FETCHED,
    BODACC_PRICES_ERROR
} from "../actions/types"

const INITIAL_STATE = {
    prices_fetching: false,
    prices_fetched: false,
    prices: [],
    prices_error: null
}

const BodaccReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BODACC_PRICES_FETCH:
            return {
                ...state,
                prices_fetching: true,
                prices_fetched: false,
                prices_error: INITIAL_STATE.prices_error
            }
        case BODACC_PRICES_FETCHED:
            return {
                ...state,
                prices_fetching: false,
                prices_fetched: true,
                prices: action.payload,
                prices_error: INITIAL_STATE.prices_error
            }
        case BODACC_PRICES_ERROR:
            return {
                ...state,
                prices_fetching: false,
                prices_fetched: false,
                prices_error: action.payload
            }
        default:
            return state
    }
}

export default BodaccReducer