import {
    REDIRECTING_TO_BEGIN,
    REDIRECTING_TO_END
} from "../actions/types"

const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    datas: null
}

const RedirectingToReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REDIRECTING_TO_BEGIN:
            return {
                ...state,
                fetching: true,
                fetched: false
            }
        case REDIRECTING_TO_END:
            return {
                ...state,
                fetching: false,
                fetched: true,
                datas: {
                    ...action.payload,
                    postalCodeInseeMatching:
                        state.datas && state.datas.postalCodeInseeMatching && !action.payload.postalCodeInseeMatching ?
                            state.datas.postalCodeInseeMatching : action.payload.postalCodeInseeMatching
                }
            }
        default:
            return state
    }
}

export default RedirectingToReducer