import { createGlobalStyle } from "styled-components"

export const globalsV2 = createGlobalStyle`
    :root {
        --Brand-primary: #0CB2AD;
        --Brand-primary-dark: #0A8F8B;
        --Brand-secondary: #3AE7B1;
        --Brand-linear: linear-gradient(140deg, #22DAD1 -1.62%, #3AE7B1 99.8%);

        --Neutral-Black: #212121;

        --Neutral-900: #001723;
        --Neutral-800: #2D393F;
        --Neutral-700: #485861;
        --Neutral-600: #71838C;
        --Neutral-500: #9DB6C3;
        --Neutral-400: #C7D3D9;
        --Neutral-300: #E6EEF2;
        --Neutral-200: #ECF2F4;
        --Neutral-100: #F2F7F9;
        --Neutral-75: #F4F8FA;
        --Neutral-50: #F9FAFB;

        --Main-color: #110D29;

        --color-light-100: #FFF;
        --color-light-200: #E6EEF2;
        --color-clear-green: #1DBD0F;
        --color-old-uec-green: #0DB3AC;
        --color-old-uec-green-rgb: 13, 179, 172;
        --color-green-blur: #F2FFFD;
        --color-secondary: #26C9C4;

        --color-blue-300: #284F63;

        --shadow-200: 0px 8px 12px 0px rgba(167, 176, 192, 0.32);
        --shadow-300: 0px 12px 20px 0px rgba(167, 176, 192, 0.24);
    }

    h1 {
        color: var(--Neutral-900, #001723);
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    h2 {
        color: var(--Neutral-Black, #212121);
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    h3 {
        color: var(--Neutral-Black, #212121);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .classic-text {
        color: var(--Neutral-900, #001723);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .primary-button {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        padding: 16px 24px;
        gap: 16px;
        margin: 20px 0 0;
        color: var(--color-light-100);
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 600;
        border-radius: 100px;
        overflow: hidden;
        cursor: pointer;
        text-decoration: none;
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
        z-index: 1;
    }

    .primary-button::before,
    .primary-button::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: var(--Brand-linear);
        border-radius: 100px;
        z-index: -1;
        transition: opacity 0.3s ease-in-out;
    }

    .primary-button::after {
        opacity: 0;
        background-image: linear-gradient(140deg, #3AE7B1 -1.62%, #22DAD1 99.8%);
    }

    .primary-button:hover {
        box-shadow: 4px 5px 27px 4px rgba(58, 231, 177, 0.3);
    }

    .primary-button:hover::after {
        opacity: 1;
    }

    /* BURGER MENU */
    /* Position and sizing of burger button */
    .header-burger-button-guide,
    .header-burger-button-scrolled-guide,
    .header-burger-button,
    .header-burger-button-scrolled,
    .public-header-burger-button {
        position: absolute;
        width: 36px;
        height: 30px;
        right: 20px;
        top: 15px;
    }

    .header-burger-button-v2 {
        position: absolute;
        width: 34px;
        height: 34px;
        right: 20px;
        top: 25px;
    }

    @media (min-width: 1186px) {
        .header-burger-button, 
        .header-burger-button-scrolled {
            display: none;
        }
    }

    @media (min-width: 1413px) {
        .header-burger-button-v2 {
            display: none;
        }
    }

    @media (min-width: 1007px) {
        .public-header-burger-button {
            display: none;
        }
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #0db3ac;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: #09a59f;
    }

    /* Color/shape of burger icon bars */
    .header-burger-button-scrolled .bm-burger-bars {
        background: #ffffff !important;
    }

    /* Color/shape of burger icon bars on hover*/
    .header-burger-button-scrolled .bm-burger-bars-hover {
        background: #ffffff !important;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 35px;
        width: 40px;
    }

    .bm-cross-button > span {
        right: 25px !important;
    }
    
    /* Color/shape of close button cross */
    .bm-cross {
        background: #0db3ac;
        height: 25px !important;
    }

    .bm-menu-wrap {
        position: fixed;
        height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
        background: white;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }
    
    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }
    
    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
    }
    
    /* Individual item */
    .bm-item {
        outline: 0 !important;
        margin-bottom: 50px;
    }
    
    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
    
    /* BURGER MENU */

    ul.dropdown li {
        margin-right: 0 !important;
    }
      
    a.dropdown-link:link,
    a.dropdown-link:visited,
    a.dropdown-link:active {
        font-weight: 400 !important;
        font-family: Lato, Roboto, Helvetica, Arial, sans-serif !important;
        color: black !important;
        border-bottom: 0px !important;
        text-shadow: initial !important;
    }

    a.dropdown-link:hover {
        font-weight: 400 !important;
        font-family: Lato, Roboto, Helvetica, Arial, sans-serif !important;
        color: #0db3ac !important;
        text-decoration: normal !important;
        border-bottom: 0px !important;
        text-shadow: initial !important;
    }

    .dropdown-button .dropdown-menu {
        position: relative;
        top: 2rem;
        z-index: 10;
    }

    .dropdown-button {
        display: flex !important;
        align-items: flex-start !important;
    }
      
    .dropdown-button:hover ul.dropdown {
        display: flex;
        flex-direction: column;
    }
      
    a.uec-dropdown-button:visited,
    a.uec-dropdown-button:link,
    a.uec-dropdown-button:active {
        font-family: Lato, Roboto, Helvetica, Arial, sans-serif !important;
        -webkit-border-radius: 21px;
        -moz-border-radius: 21px;
        border-radius: 5px;
        color: #ffffff !important;
        font-size: 14px;
        font-weight: 600;
        height: 42px;
        min-width: 100px;
        background-color: #0db3ac;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        padding-top: 12px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        line-height: 1;
        border-bottom: 0 !important;
    }

    @media (max-width: 767px) {
        .hideForm {
            display: none !important;
        }
    }

    .menu-v2-container {
        position: fixed;
        z-index: 11;

        @media (max-width: 1412px) {
            position: relative;
        }
    }

    ul.dropdown {
        margin-top: 0;
        box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15),
        0 2px 4px 0 rgba(93, 100, 148, 0.2);
        background: white;
        border-radius: 5px;
        list-style: none;
        display: none;
        margin-left: 0px;
        padding-left: 0px;
        position: absolute;
        min-width: 205px;
        padding: 10px;
        padding-right: 0px;
        padding-top: 17px;
        padding-bottom: 0;
        transition: transform 0.25s ease-out, opacity 0.25s ease-out;
        z-index: 10000;
    }

    .menu-v2 ul:not(.dropdown) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style-type: none;
        margin-bottom: 0px;
        margin-top: 20px;
    }

    .menu-v2 li {
        margin-right: 20px;
        display: flex;
        align-items: flex-end;
    }

    .menu-v2 a:hover,
    .menu-v2 a:visited,
    .menu-v2 a:link,
    .menu-v2 a:active,
    a.menu-item {
        font-family: Montserrat !important;
        color: black !important;
        font-size: 14px !important;
        text-decoration: none !important;
        line-height: 1.5;
        border-bottom: 2px solid white !important;
        margin-bottom: 15px;
        padding-bottom: 3px;
    }

    .menu-v2 a:hover,
    a.menu-item:hover,
    .menu-v2 a.hover,
    a.menu-item.hover {
        border-bottom: 2px solid #0db3ac !important;
    }

    @media (max-width: 1412px) {
        .menu-v2 {
            display: none;
        }

        .hideConnectionButtonV2 {
            display: none !important;
        }
    }
`