import { combineReducers } from "redux"
import { reducer as ResponsiveReducer } from 'react-responsive-redux'

import HomepageReducer from "./HomepageReducer"
import SubscribeBrandReducer from "./SubscribeBrandReducer"
import SubscribeProReducer from "./SubscribeProReducer"
import ConfigReducer from "./ConfigReducer"
import ProspectionStateReducer from "./ProspectionStateReducer"
import ProspectionReducer from "./ProspectionReducer"
import PopulatedCitiesReducer from "./PopulatedCitiesReducer"
import SubscribeStateReducer from "./SubscribeStateReducer"
import GuideReducer from "./GuideReducer"
import AgencyReducer from "./AgencyReducer"
import SendAgencyFormReducer from "./SendAgencyFormReducer"
import DisplayAgencyPhoneReducer from "./DisplayAgencyPhoneReducer"
import BodaccReducer from "./BodaccReducer"
import ShoppingMallsReducer from "./ShoppingMallsReducer"
import IndustrialAreasReducer from "./IndustrialAreasReducer"
import RedirectingToReducer from "./RedirectingToReducer"
import PublicPageReducer from "./PublicPageReducer"
import TransactionReducer from "./TransactionReducer"

const rootReducer = combineReducers({
    homepage: HomepageReducer,
    subscribeBrand: SubscribeBrandReducer,
    subscribePro: SubscribeProReducer,
    subscribeState: SubscribeStateReducer,
    config: ConfigReducer,
    prospections_state: ProspectionStateReducer,
    prospection: ProspectionReducer,
    populated_cities: PopulatedCitiesReducer,
    responsive: ResponsiveReducer,
    guide: GuideReducer,
    agencies: AgencyReducer,
    send_agency_form: SendAgencyFormReducer,
    display_agency_phone: DisplayAgencyPhoneReducer,
    bodacc: BodaccReducer,
    shopping_malls: ShoppingMallsReducer,
    industrial_areas: IndustrialAreasReducer,
    redirecting_to: RedirectingToReducer,
    public_page: PublicPageReducer,
    transactions: TransactionReducer
})

export default rootReducer
