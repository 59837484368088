import _ from "lodash"

import {
    SUBSCRIBE_PRO_SUBMIT,
    SUBSCRIBE_PRO_SUBMITTED,
    SUBSCRIBE_PRO_RESET,
    SUBSCRIBE_PRO_ERROR_SUBMITTED,
    SUBSCRIBE_PRO_GO_TO_STEP,
    SUBSCRIBE_PRO_CHANGE_FIRSTNAME,
    SUBSCRIBE_PRO_CHANGE_LASTNAME,
    SUBSCRIBE_PRO_CHANGE_POSITION,
    SUBSCRIBE_PRO_CHANGE_CUSTOM_POSITION,
    SUBSCRIBE_PRO_CHANGE_NETWORK,
    SUBSCRIBE_PRO_CHANGE_CUSTOM_NETWORK,
    SUBSCRIBE_PRO_CHANGE_ACTIVITY,
    SUBSCRIBE_PRO_QUIT_TERMINATED_FORM,
    SUBSCRIBE_PRO_ADD_LOCATION,
    SUBSCRIBE_PRO_REMOVE_LOCATION,
    SUBSCRIBE_PRO_CHANGE_ADDRESS,
    SUBSCRIBE_PRO_ADD_LOCATION2,
    SUBSCRIBE_PRO_REMOVE_LOCATION2,
    SUBSCRIBE_PRO_CHANGE_EMAIL,
    SUBSCRIBE_PRO_CHANGE_EMAIL_CONFIRMATION,
    SUBSCRIBE_PRO_CHANGE_PHONE,
    SUBSCRIBE_PRO_SET_MATCH_ALL_LOCATIONS,
    SUBSCRIBE_PRO_TOGGLE_PRIVACY_POLICY
} from "../actions/types"

const INITIAL_STATE = {
    submitting: false,
    submitted: false,
    errorSubmitted: false,
    currentStep: 1,
    stepDirection: "forward",
    firstname: "",
    lastname: "",
    position: "",
    custom_position: "",
    network: "Autre",
    custom_network: "",
    activity: null,
    locations: [],
    address: "",
    locations2: [],
    email: "",
    email_confirmation: "",
    phone: "",
    matchAllLocations: false,
    privacyPolicy: false
}

const SubscribeProReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUBSCRIBE_PRO_ADD_LOCATION:
            return {
                ...state,
                locations: !_.find(state.locations, {
                    libelle: action.payload.libelle
                })
                    ? [...state.locations, action.payload]
                    : state.locations
            }
        case SUBSCRIBE_PRO_REMOVE_LOCATION:
            return {
                ...state,
                locations: state.locations.filter(l => l.id !== action.payload)
            }
        case SUBSCRIBE_PRO_REMOVE_LOCATION2:
            return {
                ...state,
                locations2: INITIAL_STATE.locations2
            }
        case SUBSCRIBE_PRO_SUBMIT:
            return {
                ...state,
                submitting: true,
                submitted: false,
                errorSubmitted: false
            }
        case SUBSCRIBE_PRO_SUBMITTED:
            return {
                ...state,
                submitting: false,
                submitted: true,
                errorSubmitted: false
            }
        case SUBSCRIBE_PRO_RESET:
            return {
                ...state,
                submitting: false,
                submitted: false,
                errorSubmitted: false
            }
        case SUBSCRIBE_PRO_ERROR_SUBMITTED:
            return {
                ...state,
                submitting: false,
                submitted: false,
                errorSubmitted: action.payload
            }
        case SUBSCRIBE_PRO_GO_TO_STEP:
            return {
                ...state,
                currentStep: action.payload,
                stepDirection:
                    action.payload < state.stepDirection ? "back" : "forward"
            }
        case SUBSCRIBE_PRO_QUIT_TERMINATED_FORM:
            return {
                ...state,
                currentStep: INITIAL_STATE.currentStep
            }
        case SUBSCRIBE_PRO_CHANGE_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_LASTNAME:
            return {
                ...state,
                lastname: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_POSITION:
            return {
                ...state,
                position: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_CUSTOM_POSITION:
            return {
                ...state,
                custom_position: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_NETWORK:
            return {
                ...state,
                network: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_CUSTOM_NETWORK:
            return {
                ...state,
                custom_network: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_ACTIVITY:
            return {
                ...state,
                activity: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_ADDRESS:
            return {
                ...state,
                address: action.payload
            }
        case SUBSCRIBE_PRO_ADD_LOCATION2:
            return {
                ...state,
                locations2: [action.payload]
            }
        case SUBSCRIBE_PRO_CHANGE_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_EMAIL_CONFIRMATION:
            return {
                ...state,
                email_confirmation: action.payload
            }
        case SUBSCRIBE_PRO_CHANGE_PHONE:
            return {
                ...state,
                phone: action.payload
            }
        case SUBSCRIBE_PRO_SET_MATCH_ALL_LOCATIONS:
            return {
                ...state,
                matchAllLocations: action.payload
            }
        case SUBSCRIBE_PRO_TOGGLE_PRIVACY_POLICY:
            return {
                ...state,
                privacyPolicy: action.payload
            }
        default:
            return state
    }
}

export default SubscribeProReducer