export const SHOW_VIDEO_POPUP = "show_video_popup"
export const HIDE_VIDEO_POPUP = "hide_video_popup"
export const CHANGE_STATUS = "change_status"
export const CHANGE_TYPE = "change_type"
export const CHANGE_MIN = "change_min"
export const CHANGE_MAX = "change_max"
export const ADD_LOCATION = "add_location"
export const REMOVE_LOCATION = "remove_location"
export const SET_FILTERED_LOCATIONS = "set_fltered_locations"
export const COMPUTE_TARGET = "compute_target"
export const COMPUTED_TARGET = "computed_target"
export const CHANGE_EMAIL = "change_email"
export const CHANGE_EMAIL_CONFIRMATION = "change_email_confirmation"
export const SUBMIT_FIRST_STEP = "submit_first_step"
export const SUBMITTED_FIRST_STEP = "submitted_first_step"
export const RESET_FIRST_STEP = "reset_first_step"
export const ERROR_SUBMITTED_FIRST_STEP = "error_submitted_first_step"
export const SUBMIT_SECOND_STEP = "submit_second_step"
export const SUBMITTED_SECOND_STEP = "submitted_second_step"
export const RESET_SECOND_STEP = "reset_second_step"
export const ERROR_SUBMITTED_SECOND_STEP = "error_submitted_second_step"
export const SUBMIT_THIRD_STEP = "submit_third_step"
export const SUBMITTED_THIRD_STEP = "submitted_third_step"
export const RESET_THIRD_STEP = "reset_third_step"
export const ERROR_SUBMITTED_THIRD_STEP = "error_submitted_third_step"
export const SUBMIT_FOURTH_STEP = "submit_fourth_step"
export const SUBMITTED_FOURTH_STEP = "submitted_fourth_step"
export const RESET_FOURTH_STEP = "reset_fourth_step"
export const ERROR_SUBMITTED_FOURTH_STEP = "error_submitted_fourth_step"
export const SUBMIT_FIFTH_STEP = "submit_fifth_step"
export const SUBMITTED_FIFTH_STEP = "submitted_fifth_step"
export const RESET_FIFTH_STEP = "reset_fifth_step"
export const ERROR_SUBMITTED_FIFTH_STEP = "error_submitted_fifth_step"
export const GO_TO_STEP = "go_to_step"
export const SAVE_OLD_PHONE = "save_old_phone"
export const CHANGE_FIRSTNAME = "change_firstname"
export const CHANGE_LASTNAME = "change_lastname"
export const CHANGE_POSITION = "change_position"
export const CHANGE_BRAND = "change_brand"
export const CHANGE_ACTIVITY_TYPE = "change_activity_type"
export const CHANGE_PHONE = "change_phone"

export const NAME_CHANGED = "name_changed"
export const IS_FIELD_CHANGED = "is_field_changed"
export const REST_SURFACE_ACCESS_CHANGED = "rest_surface_access_changed"
export const GROUNDFLOOR_SURFACE_CHANGED = "groundfloor_surface_changed"
export const REST_SURFACE_CHANGED = "rest_surface_changed"
export const SURFACE_ATTRIBUTES_CHANGED = "surface_attributes_changed"
export const FACADE_LENGTH_CHANGED = "facade_length_changed"
export const HEIGHT_LENGTH_CHANGED = "height_length_changed"
export const COS_CHANGED = "cos_changed"
export const MIN_BUILDING_HEIGHT_CHANGED = "min_building_height_changed"
export const LEASE_CHANGED = "lease_changed"
export const LEASE_ATTRIBUTES_CHANGED = "lease_attributes_changed"
export const PRICE_CHANGED = "price_changed"
export const PRICE_ATTRIBUTES_CHANGED = "price_attributes_changed"
export const RIGHT_CHANGED = "right_changed"
export const CENTER_CHANGED = "center_changed"
export const NUMBER_ONE_CHANGED = "number_one_changed"
export const NUMBER_ONE_BIS_CHANGED = "number_one_bis_changed"
export const AXE_PASSANT_CHANGED = "axe_passant_changed"
export const MALL_CHANGED = "mall_changed"
export const GALERIE_CHANGED = "galerie_changed"
export const TRANSPORTATION_CHANGED = "transportation_changed"
export const SUBURB_CHANGED = "suburb_changed"
export const COMMERCIAL_AREA_CHANGED = "commercial_area_changed"
export const INDUSTRIAL_AREA_CHANGED = "industrial_area_changed"
export const BUY_CHANGED = "buy_changed"
export const REQUIRED_LICENSE_4_CHANGED = "required_license_4_changed"
export const ALLOWED_CATERING_INDUSTRY_CHANGED =
    "allowed_catering_industry_changed"
export const DIAMETER_CHANGED = "diameter_changed"
export const ALLOWED_CATERING_INDUSTRY_WITH_EXTRACTION_CHANGED =
    "allowed_catering_industry_with_extraction_changed"
export const PMR_CHANGED = "pmr_changed"
export const PARKING_CHANGED = "parking_changed"
export const CLOSE_PARKING_CHANGED = "close_parking_changed"
export const COMMENT_CHANGED = "comment_changed"
export const LOCATIONS_COMMENT_CHANGED = "locations_comment_changed"
export const ACTIVITY_TYPE_CHANGED = "activity_type_changed"
export const DISPLAY_NUMBER_CHANGED = "display_number_changed"
export const FUNDING_CHANGED = "funding_changed"
export const CONCEPT_CHANGED = "concept_changed"
export const COMMERCIAL_INDUSTRIAL_DESTINATION_CHANGED =
    "commercial_industrial_destination_changed"
export const HABITATION_DESTINATION_CHANGED = "habitation_destination_changed"
export const BUY_ONLY_CHANGED = "buy_only_changed"
export const ALLOW_NON_BUILDING_LAND_CHANGED = "allow_non_building_land_changed"

export const QUIT_TERMINATED_FORM = "quit_terminated_form"
export const SUBSCRIBE_BRAND_TOGGLE_PRIVACY_POLICY =
    "subscribe_brand_toggle_privacy_policy"

export const SET_CONFIG = "set_config"

export const PROSPECTIONS_STATE_FETCH = "prospections_state_fetch"
export const PROSPECTIONS_STATE_FETCHED = "prospections_state_fetched"
export const PROSPECTIONS_STATE_RESET = "prospections_state_reset"
export const PROSPECTIONS_STATE_MARK_CHANGED = "prospections_state_mark_changed"
export const PROSPECTIONS_STATE_MARK_VALIDATE =
    "prospections_state_mark_validate"
export const PROSPECTIONS_STATE_MARK_VALIDATED =
    "prospections_state_mark_validated"
export const PROSPECTIONS_STATE_MARK_ERROR = "prospections_state_mark_error"
export const PROSPECTIONS_STATE_MARK_FETCHED = "prospections_state_mark_fetched"

export const SET_CURRENT_PARAMETERS = "set_current_parameters"
export const SET_PROSPECTION_CURRENT_PARAMETERS =
    "set_prospection_current_parameters"

export const PROSPECTION_FETCH = "prospection_fetch"
export const PROSPECTION_FETCHED = "prospection_fetched"
export const PROSPECTION_ERROR = "prospection_error"
export const PROSPECTION_RESET = "prospection_reset"
export const PROSPECTION_MARK_CHANGED = "prospection_mark_changed"
export const PROSPECTION_MARK_VALIDATE = "prospection_mark_validate"
export const PROSPECTION_MARK_VALIDATED = "prospection_mark_validated"
export const PROSPECTION_MARK_ERROR = "prospection_mark_error"
export const PROSPECTION_MARK_FETCHED = "prospection_mark_fetched"

export const SIMILAR_PROSPECTIONS_FETCH = "similar_prospections_fetch"
export const SIMILAR_PROSPECTIONS_FETCHED = "similar_prospections_fetched"
export const SIMILAR_PROSPECTIONS_ERROR = "similar_prospections_error"

export const LAST_PROSPECTIONS_FETCH = "last_prospections_fetch"
export const LAST_PROSPECTIONS_FETCHED = "last_prospections_fetched"
export const LAST_PROSPECTIONS_ERROR = "last_prospections_error"

export const POPULATED_CITIES_FETCH = "populated_cities_fetch"
export const POPULATED_CITIES_FETCHED = "populated_cities_fetched"
export const POPULATED_CITIES_ERROR = "populated_cities_error"

export const AROUND_LOCATION_CITIES_FETCH = "around_location_cities_fetch"
export const AROUND_LOCATION_CITIES_FETCHED = "around_location_cities_fetched"

export const SHOW_PRO_SUBSCRIBE_DIALOG = "show_pro_subscribe_dialog"
export const HIDE_PRO_SUBSCRIBE_DIALOG = "hide_pro_subscribe_dialog"

export const SHOW_STATE_SUBSCRIBE_DIALOG = "show_state_subscribe_dialog"
export const HIDE_STATE_SUBSCRIBE_DIALOG = "hide_state_subscribe_dialog"

export const SHOW_DEMO_DIALOG = "show_demo_dialog"
export const HIDE_DEMO_DIALOG = "hide_demo_dialog"

export const SUBSCRIBE_PRO_SUBMIT = "subscribe_pro_submit"
export const SUBSCRIBE_PRO_SUBMITTED = "subscribe_pro_submitted"
export const SUBSCRIBE_PRO_RESET = "subscribe_pro_reset"
export const SUBSCRIBE_PRO_ERROR_SUBMITTED = "subscribe_pro_error_submitted"
export const SUBSCRIBE_PRO_GO_TO_STEP = "subscribe_pro_go_to_step"
export const SUBSCRIBE_PRO_QUIT_TERMINATED_FORM =
    "subscribe_pro_quit_terminated_form"
export const SUBSCRIBE_PRO_CHANGE_FIRSTNAME = "subcribe_pro_change_firstname"
export const SUBSCRIBE_PRO_CHANGE_LASTNAME = "subscribe_pro_change_lastname"
export const SUBSCRIBE_PRO_CHANGE_POSITION = "subscribe_pro_change_position"
export const SUBSCRIBE_PRO_CHANGE_CUSTOM_POSITION =
    "subscribe_pro_change_custom_position"
export const SUBSCRIBE_PRO_CHANGE_NETWORK = "subscribe_pro_change_network"
export const SUBSCRIBE_PRO_CHANGE_CUSTOM_NETWORK =
    "subscribe_pro_change_custom_network"
export const SUBSCRIBE_PRO_CHANGE_ACTIVITY = "subscribe_pro_change_activity"

export const SUBSCRIBE_PRO_ADD_LOCATION = "subscribe_pro_add_location"
export const SUBSCRIBE_PRO_ADD_LOCATION2 = "subscribe_pro_add_location2"
export const SUBSCRIBE_PRO_REMOVE_LOCATION2 = "subscribe_pro_remove_location2"
export const SUBSCRIBE_PRO_REMOVE_LOCATION = "subscribe_pro_remove_location"
export const SUBSCRIBE_PRO_CHANGE_ADDRESS = "subscribe_pro_change_address"
export const SUBSCRIBE_PRO_CHANGE_EMAIL = "subscribe_pro_change_email"
export const SUBSCRIBE_PRO_CHANGE_EMAIL_CONFIRMATION =
    "subscribe_pro_change_email_confirmation"
export const SUBSCRIBE_PRO_CHANGE_PHONE = "subscribe_pro_change_phone"
export const SUBSCRIBE_PRO_SET_MATCH_ALL_LOCATIONS =
    "subscribe_pro_set_match_all_locations"
export const SUBSCRIBE_PRO_TOGGLE_PRIVACY_POLICY =
    "subscribe_pro_toggle_privacy_policy"

export const SUBSCRIBE_STATE_SUBMIT = "subscribe_state_submit"
export const SUBSCRIBE_STATE_SUBMITTED = "subscribe_state_submitted"
export const SUBSCRIBE_STATE_ERROR_SUBMITTED = "subscribe_state_error_submitted"
export const SUBSCRIBE_STATE_RESET = "subscribe_state_reset"
export const SUBSCRIBE_STATE_GO_TO_STEP = "subscribe_state_go_to_step"
/* first step */
export const SUBSCRIBE_STATE_CHANGE_FIRSTNAME =
    "subscribe_state_change_firstname"
export const SUBSCRIBE_STATE_CHANGE_LASTNAME = "subscribe_state_change_lastname"
export const SUBSCRIBE_STATE_CHANGE_POSITION = "subscribe_state_change_position"
export const SUBSCRIBE_STATE_CHANGE_CUSTOM_POSITION =
    "subscribe_state_change_custom_position"
/* first step */
export const SUBSCRIBE_STATE_QUIT_TERMINATED_FORM =
    "subscribe_state_quit_terminated_form"
export const SUBSCRIBE_STATE_ADD_LOCATION = "subscribe_state_add_location"
export const SUBSCRIBE_STATE_REMOVE_LOCATION = "susbcribe_state_remove_location"
export const SUBSCRIBE_STATE_CHANGE_ADDRESS = "subscribe_state_change_address"
export const SUBSCRIBE_STATE_ADD_LOCATION2 = "subscribe_state_add_location2"
export const SUBSCRIBE_STATE_REMOVE_LOCATION2 =
    "subscribe_state_remove_location2"
export const SUBSCRIBE_STATE_CHANGE_EMAIL = "subscribe_state_change_email"
export const SUBSCRIBE_STATE_CHANGE_EMAIL_CONFIRMATION =
    "subscribe_state_change_email_confirmation"
export const SUBSCRIBE_STATE_CHANGE_PHONE = "subscribe_state_change_phone"
export const SUBSCRIBE_STATE_TOGGLE_PRIVACY_POLICY =
    "subscribe_state_toggle_privacy_policy"

export const PROSPECTIONS_COUNT_FETCH = "prospections_count_fetch"
export const PROSPECTIONS_COUNT_FETCHED = "prospections_count_fetched"
export const PROSPECTIONS_COUNT_ERROR = "prospections_count_error"

export const CITIES_PUBLIC_PAGE_FETCH = "cities_public_page_fetch"
export const CITIES_PUBLIC_PAGE_FETCHED = "cities_public_page_fetched"
export const CITIES_PUBLIC_PAGE_ERROR = "cities_public_page_error"

export const DEPARTMENTS_PUBLIC_PAGE_FETCH = "departments_public_page_fetch"
export const DEPARTMENTS_PUBLIC_PAGE_FETCHED = "departments_public_page_fetched"
export const DEPARTMENTS_PUBLIC_PAGE_ERROR = "departments_public_page_error"

export const STATES_PUBLIC_PAGE_FETCH = "states_public_page_fetch"
export const STATES_PUBLIC_PAGE_FETCHED = "states_public_page_fetched"
export const STATES_PUBLIC_PAGE_ERROR = "states_public_page_error"

export const AROUND_LOCATION_FETCH = "around_location_fetch"
export const AROUND_LOCATION_FETCHED = "around_location_fetched"

export const GUIDE_MARK_VALIDATE = "guide_mark_validate"
export const GUIDE_MARK_VALIDATED = "guide_mark_validated"
export const GUIDE_MARK_ERROR = "guide_mark_error"
export const GUIDE_MARK_FETCHED = "guide_mark_fetched"
export const GUIDE_MARK_CHANGED = "guide_mark_changed"

export const PROSPECTIONS_VIEW_INFOS_FETCH = "prospections_view_infos_fetch"
export const PROSPECTIONS_VIEW_INFOS_FETCHED = "prospections_view_infos_fetched"

export const AGENCY_FETCH = "agency_fetch"
export const AGENCY_FETCHED = "agency_fetched"
export const AGENCY_RESET = "agency_reset"
export const AGENCY_ERROR = "agency_error"

export const AGENCY_SEND = "agency_send"
export const AGENCY_SENT = "agency_sent"
export const AGENCY_SEND_ERROR = "agency_send_error"
export const AGENCY_IDENTITY_CHANGED = "agency_identity_changed"
export const AGENCY_EMAIL_CHANGED = "agency_email_changed"
export const AGENCY_PHONE_CHANGED = "agency_phone_changed"
export const AGENCY_MESSAGE_CHANGED = "agency_message_changed"
export const AGENCY_PROJECT_CHANGED = "agency_project_changed"
export const AGENCY_LOCATION_CHANGED = "agency_location_changed"
export const SEND_AGENCY_FORM_RESET = "send_agency_form_reset"
export const SEND_AGENCY_FORM_FIELDS_RESET = "send_agency_form_fields_reset"
export const SEND_AGENCY_FORM_SENT_RESET = "send_agency_form_sent_reset"
export const SEND_AGENCY_FORM_RESULT_POPUP_OPEN = "send_agency_form_result_popup_open"
export const SEND_AGENCY_FORM_RESULT_POPUP_CLOSE = "send_agency_form_result_popup_close"
export const AGENCY_PRIVACY_POLICY_TOGGLED = "agency_privacy_policy_toggled"

export const DISPLAY_AGENCY_PHONE_RESET = "display_agency_phone_reset"
export const DISPLAY_AGENCY_PHONE_FETCH = "display_agency_phone_fetch"
export const DISPLAY_AGENCY_PHONE_FETCHED = "display_agency_phone_fetched"
export const DISPLAY_AGENCY_PHONE_ERROR = "display_agency_phone_error"

export const BODACC_PRICES_FETCH = "bodacc_prices_fetch"
export const BODACC_PRICES_FETCHED = "bodacc_prices_fetched"
export const BODACC_PRICES_ERROR = "bodacc_prices_error"

export const SHOPPING_MALLS_FETCH = "shopping_malls_fetch"
export const SHOPPING_MALLS_FETCHED = "shopping_malls_fetched"
export const SHOPPING_MALLS_ERROR = "shopping_malls_error"

export const CITIES_PUBLIC_PAGE_FETCH_V2 = "cities_public_page_fetch_v2"
export const CITIES_PUBLIC_PAGE_FETCHED_V2 = "cities_public_page_fetched_v2"
export const CITIES_PUBLIC_PAGE_ERROR_V2 = "cities_public_page_error_v2"

export const DEPARTMENTS_PUBLIC_PAGE_FETCH_V2 = "departments_public_page_fetch_v2"
export const DEPARTMENTS_PUBLIC_PAGE_FETCHED_V2 = "departments_public_page_fetched_v2"
export const DEPARTMENTS_PUBLIC_PAGE_ERROR_V2 = "departments_public_page_error_v2"

export const STATES_PUBLIC_PAGE_FETCH_V2 = "states_public_page_fetch_v2"
export const STATES_PUBLIC_PAGE_FETCHED_V2 = "states_public_page_fetched_v2"
export const STATES_PUBLIC_PAGE_ERROR_V2 = "states_public_page_error_v2"

export const COUNTRIES_PUBLIC_PAGE_FETCH_V2 = "countries_public_page_fetch_v2"
export const COUNTRIES_PUBLIC_PAGE_FETCHED_V2 = "countries_public_page_fetched_v2"
export const COUNTRIES_PUBLIC_PAGE_ERROR_V2 = "countries_public_page_error_v2"

export const INDUSTRIAL_AREAS_FETCH = "industrial_areas_fetch"
export const INDUSTRIAL_AREAS_FETCHED = "industrial_areas_fetched"
export const INDUSTRIAL_AREAS_ERROR = "industrial_areas_error"

export const REDIRECTING_TO_BEGIN = "redirecting_to_begin"
export const REDIRECTING_TO_END = "redirecting_to_end"

export const ADS_DATAS_PUBLIC_PAGE_FETCHED = "ads_datas_public_page_fetched"

export const AGENCY_PROFILE_FETCH = "agency_profile_fetch"
export const AGENCY_PROFILE_FETCHED = "agency_profile_fetched"
export const AGENCY_PROFILE_RESET = "agency_profile_reset"
export const AGENCY_PROFILE_ERROR = "agency_profile_error"

export const TRANSACTIONS_FETCH = "transactions_fetch"
export const TRANSACTIONS_FETCHED = "transactions_fetched"
export const TRANSACTIONS_RESET = "transactions_reset"
export const TRANSACTIONS_COUNT_FETCH = "transactions_count_fetch"
export const TRANSACTIONS_COUNT_FETCHED = "transactions_count_fetched"
export const TRANSACTIONS_COUNT_RESET = "transactions_count_reset"
export const TRANSACTIONS_COUNT_ERROR = "transactions_count_error"

export const SET_UA = "set_ua"