import {
    POPULATED_CITIES_FETCH,
    POPULATED_CITIES_FETCHED,
    POPULATED_CITIES_ERROR,
    AROUND_LOCATION_CITIES_FETCH,
    AROUND_LOCATION_CITIES_FETCHED
} from "../actions/types"

const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    populated_cities: {},
    around_fetching: false,
    around_fetched: false,
    around_location_cities: {},
    error: null,
    ssr: false
}

const PopulatedCitiesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case POPULATED_CITIES_FETCH:
            return {
                ...state,
                fetching: true,
                fetched: false,
                error: null
            }
        case POPULATED_CITIES_FETCHED:
            return {
                ...state,
                populated_cities: {
                    ...state.populated_cities,
                    [action.entityType +
                    "_" +
                    action.state +
                    action.locations.reduce(
                        (id, currentValue) => id + "_" + currentValue.id,
                        ""
                    )]: action.payload
                },
                fetching: false,
                fetched: true,
                ssr: action.ssr
            }
        case AROUND_LOCATION_CITIES_FETCH:
            return {
                ...state,
                around_fetching: true,
                around_fetched: false,
                error: null
            }
        case AROUND_LOCATION_CITIES_FETCHED:
            return {
                ...state,
                around_location_cities: {
                    ...state.around_location_cities,
                    [
                        action.state +
                        "_" +
                        action.location.id
                    ]: action.payload
                },
                around_fetching: false,
                around_fetched: true,
                ssr: action.ssr
            }
        case POPULATED_CITIES_ERROR:
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: action.payload,
                ssr: action.ssr
            }
        default:
            return state
    }
}

export default PopulatedCitiesReducer
