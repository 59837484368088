import {
    AGENCY_FETCH,
    AGENCY_FETCHED,
    AGENCY_RESET,
    AGENCY_ERROR,
    AGENCY_PROFILE_FETCH,
    AGENCY_PROFILE_FETCHED,
    AGENCY_PROFILE_RESET,
    AGENCY_PROFILE_ERROR
} from "../actions/types"

const INITIAL_STATE = {
    fetching: false,
    fetched: false,
    list: [],
    page: 0,
    hits_per_page: 10,
    hits: null,
    error: null,
    state: null,
    locations: null,
    filters: null,
    profile_fetching: false,
    profile_fetched: false,
    profile_error: null,
    agency_profile: null
}

const AgencyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AGENCY_FETCH:
            return {
                ...state,
                fetching: true,
                fetched: false
            }
        case AGENCY_ERROR:
            return {
                ...state,
                fetching: false,
                fetched: false,
                error: action.payload
            }
        case AGENCY_FETCHED:
            return {
                ...state,
                fetching: false,
                fetched: true,
                list: action.payload.list,
                page: action.payload.page,
                hits_per_page: action.payload.hitsPerPage,
                hits: action.payload.nbHits,
                state: action.state,
                locations: action.locations,
                filters: action.filters
            }
        case AGENCY_RESET:
            return INITIAL_STATE
        case AGENCY_PROFILE_FETCH:
            return {
                ...state,
                profile_fetching: true,
                profile_fetched: false,
                profile_error: INITIAL_STATE.profile_error
            }
        case AGENCY_PROFILE_ERROR:
            return {
                ...state,
                profile_fetching: false,
                profile_fetched: false,
                profile_error: action.payload
            }
        case AGENCY_PROFILE_FETCHED:
            return {
                ...state,
                profile_fetching: false,
                profile_fetched: true,
                profile_agency: action.payload,
                profile_error: INITIAL_STATE.profile_error
            }
        case AGENCY_PROFILE_RESET:
            return { ...state,
                profile_fetching: INITIAL_STATE.profile_fetching,
                profile_fetched: INITIAL_STATE.profile_fetched,
                profile_agency: INITIAL_STATE.profile_agency,
                profile_error: INITIAL_STATE.profile_error
            }
        default:
            return state
    }
}

export default AgencyReducer