import React, { Component } from "react"
import { isMobileOnly } from "react-device-detect"

import { crisp as CrispStyle } from "../styles"

class CrispStyleContainer extends Component {
    constructor (props) {
        super(props)

        this.state = {
            isMobileOnly: null
        }
    }

    componentDidMount() {
        if (isMobileOnly !== this.state.isMobileOnly) {
            this.setState({ isMobileOnly })
        }
    }

    render() {
        const { router } = this.props

        return (
            <CrispStyle
                router={router}
                isMobileOnly={this.state.isMobileOnly}
            />
        )
    }
}

export default CrispStyleContainer