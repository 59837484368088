import _ from "lodash"

import {
    SUBSCRIBE_STATE_SUBMIT,
    SUBSCRIBE_STATE_SUBMITTED,
    SUBSCRIBE_STATE_RESET,
    SUBSCRIBE_STATE_ERROR_SUBMITTED,
    SUBSCRIBE_STATE_GO_TO_STEP,
    SUBSCRIBE_STATE_CHANGE_FIRSTNAME,
    SUBSCRIBE_STATE_CHANGE_LASTNAME,
    SUBSCRIBE_STATE_CHANGE_POSITION,
    SUBSCRIBE_STATE_CHANGE_CUSTOM_POSITION,
    SUBSCRIBE_STATE_QUIT_TERMINATED_FORM,
    SUBSCRIBE_STATE_ADD_LOCATION,
    SUBSCRIBE_STATE_REMOVE_LOCATION,
    SUBSCRIBE_STATE_CHANGE_ADDRESS,
    SUBSCRIBE_STATE_ADD_LOCATION2,
    SUBSCRIBE_STATE_REMOVE_LOCATION2,
    SUBSCRIBE_STATE_CHANGE_EMAIL,
    SUBSCRIBE_STATE_CHANGE_EMAIL_CONFIRMATION,
    SUBSCRIBE_STATE_CHANGE_PHONE,
    SUBSCRIBE_STATE_TOGGLE_PRIVACY_POLICY
} from "../actions/types"

const INITIAL_STATE = {
    submitting: false,
    submitted: false,
    errorSubmitted: false,
    currentStep: 1,
    stepDirection: "forward",
    firstname: "",
    lastname: "",
    position: "",
    custom_position: "",
    locations: [],
    address: "",
    locations2: [],
    email: "",
    email_confirmation: "",
    phone: "",
    privacyPolicy: false
}

const SubscribeStateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUBSCRIBE_STATE_ADD_LOCATION:
            return {
                ...state,
                locations: !_.find(state.locations, {
                    libelle: action.payload.libelle
                })
                    ? [...state.locations, action.payload]
                    : state.locations
            }
        case SUBSCRIBE_STATE_REMOVE_LOCATION:
            return {
                ...state,
                locations: state.locations.filter(l => l.id !== action.payload)
            }
        case SUBSCRIBE_STATE_REMOVE_LOCATION2:
            return {
                ...state,
                locations2: INITIAL_STATE.locations2
            }
        case SUBSCRIBE_STATE_SUBMIT:
            return {
                ...state,
                submitting: true,
                submitted: false,
                errorSubmitted: false
            }
        case SUBSCRIBE_STATE_SUBMITTED:
            return {
                ...state,
                submitting: false,
                submitted: true,
                errorSubmitted: false
            }
        case SUBSCRIBE_STATE_RESET:
            return {
                ...state,
                submitting: false,
                submitted: false,
                errorSubmitted: false
            }
        case SUBSCRIBE_STATE_ERROR_SUBMITTED:
            return {
                ...state,
                submitting: false,
                submitted: false,
                errorSubmitted: action.payload
            }
        case SUBSCRIBE_STATE_GO_TO_STEP:
            return {
                ...state,
                currentStep: action.payload,
                stepDirection:
                    action.payload < state.stepDirection ? "back" : "forward"
            }
        case SUBSCRIBE_STATE_QUIT_TERMINATED_FORM:
            return {
                ...state,
                currentStep: INITIAL_STATE.currentStep
            }
        case SUBSCRIBE_STATE_CHANGE_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload
            }
        case SUBSCRIBE_STATE_CHANGE_LASTNAME:
            return {
                ...state,
                lastname: action.payload
            }
        case SUBSCRIBE_STATE_CHANGE_POSITION:
            return {
                ...state,
                position: action.payload
            }
        case SUBSCRIBE_STATE_CHANGE_CUSTOM_POSITION:
            return {
                ...state,
                custom_position: action.payload
            }
        case SUBSCRIBE_STATE_CHANGE_ADDRESS:
            return {
                ...state,
                address: action.payload
            }
        case SUBSCRIBE_STATE_ADD_LOCATION2:
            return {
                ...state,
                locations2: [action.payload]
            }
        case SUBSCRIBE_STATE_CHANGE_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case SUBSCRIBE_STATE_CHANGE_EMAIL_CONFIRMATION:
            return {
                ...state,
                email_confirmation: action.payload
            }
        case SUBSCRIBE_STATE_CHANGE_PHONE:
            return {
                ...state,
                phone: action.payload
            }
        case SUBSCRIBE_STATE_TOGGLE_PRIVACY_POLICY:
            return {
                ...state,
                privacyPolicy: action.payload
            }
        default:
            return state
    }
}

export default SubscribeStateReducer