import _ from "lodash"

import {
    AGENCY_SEND,
    AGENCY_SENT,
    AGENCY_SEND_ERROR,
    AGENCY_IDENTITY_CHANGED,
    AGENCY_EMAIL_CHANGED,
    AGENCY_PHONE_CHANGED,
    AGENCY_PROJECT_CHANGED,
    AGENCY_MESSAGE_CHANGED,
    AGENCY_LOCATION_CHANGED,
    SEND_AGENCY_FORM_RESET,
    SEND_AGENCY_FORM_FIELDS_RESET,
    SEND_AGENCY_FORM_SENT_RESET,
    SEND_AGENCY_FORM_RESULT_POPUP_OPEN,
    SEND_AGENCY_FORM_RESULT_POPUP_CLOSE,
    AGENCY_PRIVACY_POLICY_TOGGLED
} from "../actions/types"

const INITIAL_STATE = {
    identity: "",
    email: "",
    phone: "",
    message: "",
    location: "",
    error: null,
    sending: false,
    sent: false,
    popup: false,
    privacy_policy: false,
    project: ""
}

const SendAgencyFormReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AGENCY_SEND:
            return {
                ...state,
                sending: true,
                sent: false
            }
        case AGENCY_SENT:
            return {
                ...state,
                sending: false,
                sent: true
            }
        case AGENCY_SEND_ERROR:
            return {
                ...state,
                sending: false,
                sent: false,
                error: action.payload
            }
        case AGENCY_IDENTITY_CHANGED:
            return {
                ...state,
                identity: action.payload
            }
        case AGENCY_EMAIL_CHANGED:
            return {
                ...state,
                email: action.payload
            }
        case AGENCY_PHONE_CHANGED:
            return {
                ...state,
                phone: action.payload
            }
        case AGENCY_PROJECT_CHANGED:
            return {
                ...state,
                project: action.payload
            }
        case AGENCY_MESSAGE_CHANGED:
            return {
                ...state,
                message: action.payload
            }
        case AGENCY_LOCATION_CHANGED:
            return {
                ...state,
                location: action.payload
            }
        case SEND_AGENCY_FORM_RESULT_POPUP_OPEN:
            return {
                ...state,
                popup: true
            }
        case SEND_AGENCY_FORM_RESULT_POPUP_CLOSE:
            return {
                ...state,
                popup: false
            }
        case AGENCY_PRIVACY_POLICY_TOGGLED:
            return {
                ...state,
                privacy_policy: !state.privacy_policy
            }
        case SEND_AGENCY_FORM_RESET:
            return INITIAL_STATE
        case SEND_AGENCY_FORM_FIELDS_RESET:
            return {
                ...state,
                ..._.omit(INITIAL_STATE, ['sent'])
            }
        case SEND_AGENCY_FORM_SENT_RESET:
            return {
                ...state,
                ..._.pick(INITIAL_STATE, ['sent'])
            }
        default:
            return state
    }
}

export default SendAgencyFormReducer