import {
    GUIDE_MARK_VALIDATE,
    GUIDE_MARK_VALIDATED,
    GUIDE_MARK_ERROR,
    GUIDE_MARK_FETCHED,
    GUIDE_MARK_CHANGED
} from "../actions/types"

const INITIAL_STATE = {
    mark: null,
    form_mark: null,
    mark_validated: false,
    mark_validating: false,
    mark_error: null
}

const GuideReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GUIDE_MARK_CHANGED:
            return {
                ...state,
                form_mark: action.payload
            }
        case GUIDE_MARK_VALIDATE:
            return {
                ...state,
                mark_validating: true,
                mark_validated: false,
                mark_error: INITIAL_STATE.mark_error
            }
        case GUIDE_MARK_VALIDATED:
            return {
                ...state,
                mark_validating: false,
                mark_validated: true,
                mark_error: INITIAL_STATE.mark_error
            }
        case GUIDE_MARK_ERROR:
            return {
                ...state,
                mark_validating: false,
                mark_validated: false,
                mark_error: action.payload
            }
        case GUIDE_MARK_FETCHED:
            return {
                ...state,
                mark: action.payload
            }
        default:
            return state
    }
}

export default GuideReducer