import axios from "axios"
import axiosRetry from "axios-retry"

axiosRetry(axios, {
    retryCondition: error => {
        return (
            axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            error.code === "ECONNABORTED"
        )
    },
    retryDelay: axiosRetry.exponentialDelay
})

export default axios
