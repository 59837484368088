const reverseSpecialCase = [
    {
        real_insee: "75101",
        insee: "75056",
        cp: "75001"
    },
    {
        real_insee: "75102",
        insee: "75056",
        cp: "75002"
    },
    {
        real_insee: "75103",
        insee: "75056",
        cp: "75003"
    },
    {
        real_insee: "75104",
        insee: "75056",
        cp: "75004"
    },
    {
        real_insee: "75105",
        insee: "75056",
        cp: "75005"
    },
    {
        real_insee: "75106",
        insee: "75056",
        cp: "75006"
    },
    {
        real_insee: "75107",
        insee: "75056",
        cp: "75007"
    },
    {
        real_insee: "75108",
        insee: "75056",
        cp: "75008"
    },
    {
        real_insee: "75109",
        insee: "75056",
        cp: "75009"
    },
    {
        real_insee: "75110",
        insee: "75056",
        cp: "75010"
    },
    {
        real_insee: "75111",
        insee: "75056",
        cp: "75011"
    },
    {
        real_insee: "75112",
        insee: "75056",
        cp: "75012"
    },
    {
        real_insee: "75113",
        insee: "75056",
        cp: "75013"
    },
    {
        real_insee: "75114",
        insee: "75056",
        cp: "75014"
    },
    {
        real_insee: "75115",
        insee: "75056",
        cp: "75015"
    },
    {
        real_insee: "75116",
        insee: "75056",
        cp: "75016"
    },
    {
        real_insee: "75117",
        insee: "75056",
        cp: "75017"
    },
    {
        real_insee: "75118",
        insee: "75056",
        cp: "75018"
    },
    {
        real_insee: "75119",
        insee: "75056",
        cp: "75019"
    },
    {
        real_insee: "75120",
        insee: "75056",
        cp: "75020"
    },
    {
        real_insee: "13201",
        insee: "13055",
        cp: "13001"
    },
    {
        real_insee: "13202",
        insee: "13055",
        cp: "13002"
    },
    {
        real_insee: "13203",
        insee: "13055",
        cp: "13003"
    },
    {
        real_insee: "13204",
        insee: "13055",
        cp: "13004"
    },
    {
        real_insee: "13205",
        insee: "13055",
        cp: "13005"
    },
    {
        real_insee: "13206",
        insee: "13055",
        cp: "13006"
    },
    {
        real_insee: "13207",
        insee: "13055",
        cp: "13007"
    },
    {
        real_insee: "13208",
        insee: "13055",
        cp: "13008"
    },
    {
        real_insee: "13209",
        insee: "13055",
        cp: "13009"
    },
    {
        real_insee: "13210",
        insee: "13055",
        cp: "13010"
    },
    {
        real_insee: "13211",
        insee: "13055",
        cp: "13011"
    },
    {
        real_insee: "13212",
        insee: "13055",
        cp: "13012"
    },
    {
        real_insee: "13213",
        insee: "13055",
        cp: "13013"
    },
    {
        real_insee: "13214",
        insee: "13055",
        cp: "13014"
    },
    {
        real_insee: "13215",
        insee: "13055",
        cp: "13015"
    },
    {
        real_insee: "13216",
        insee: "13055",
        cp: "13016"
    },
    {
        real_insee: "69381",
        insee: "69123",
        cp: "69001"
    },
    {
        real_insee: "69382",
        insee: "69123",
        cp: "69002"
    },
    {
        real_insee: "69383",
        insee: "69123",
        cp: "69003"
    },
    {
        real_insee: "69384",
        insee: "69123",
        cp: "69004"
    },
    {
        real_insee: "69385",
        insee: "69123",
        cp: "69005"
    },
    {
        real_insee: "69386",
        insee: "69123",
        cp: "69006"
    },
    {
        real_insee: "69387",
        insee: "69123",
        cp: "69007"
    },
    {
        real_insee: "69388",
        insee: "69123",
        cp: "69008"
    },
    {
        real_insee: "69389",
        insee: "69123",
        cp: "69009"
    }
]

export default reverseSpecialCase
