import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'

import reducers from '../reducers'

const ConfigureStore = (initialState = {}) => {
    let middleware = applyMiddleware(ReduxThunk)

    if (
        process.env.NODE_ENV !== 'production' &&
        typeof window !== 'undefined' &&
        window.devToolsExtension
    ) {
        const devtools = window.devToolsExtension()
        middleware = compose(
            middleware,
            devtools
        )
    }

    const store = createStore(reducers, { ...initialState }, middleware)

    return store
}

export default ConfigureStore
