import {
    SET_CONFIG,
    SET_UA
} from '../actions/types'

const INITIAL_STATE = {
    config: {},
    ua: null
}

const ConfigReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case SET_CONFIG:
        return {
            ...state,
            config: action.payload
        }
    case SET_UA:
        return {
            ...state,
            ua: action.payload
        }
    default:
        return state
    }
}

export default ConfigReducer
